import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';


function SimpleDonutChart(props) {
  let [series, series변경] = useState([]);
  let [options, options변경] = useState({
    responsive: [
      {
        breakpoint: 750,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: -30,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    theme: {
      mode: "dark",
    },
    chart: {
      type: "donut",
      background: "transparent",
      toolbar: {
        show: false,
      },
      offsetY: 0,
      offsetX: 0,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        borderSize: 0,
        offsetY: 20,
      },
      stroke: {
        colors: undefined,
      },
    },
    stroke: {
      colors: "#fff",
      width: 0.9,
    },
    grid: {
      style: {
        colors: ["#fff"],
      },
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.ceil(val) + "%";
      },
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
  });

  useEffect(() => {
    series변경(DataConverting(props.data));
  }, [props.data]);
  //   let series = DataConverting(props.data);
  function DataConverting(origindata) {
    var data = origindata;
    var value = [];
    var labels = [];
    if (data === undefined) return undefined;
    options.labels = origindata.label;
    options변경(options);
    return data.value;
  }

  return (
    <div>
      <div>
        {series === undefined ? (
          <img
            style={{ width: "50%", height: "50%" }}
            src={process.env.PUBLIC_URL + "Nodata.svg"}
            alt={""}
          />
        ) : (
          <Chart options={options} series={series} type="donut" />
        )}
      </div>
    </div>
  );
}

export default SimpleDonutChart;
