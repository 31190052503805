import React, { useEffect, useState } from "react";
import Styles from "./TableRow.module.css";

const TableRow = (props) => {
  let [data, data변경] = useState([]);
  useEffect(() => {
    data변경(DataConverting(props.data));
  }, [props.data]);
  // label : {}
  // value : {}
  //
  function DataConverting(origindata) {
    var data = origindata;
    var row = [];
    if (!data) return [];
    for (let i = 0; i < data.label.length; i++) {
      row.push({
        title: data.label[i],
        totalCount: data.value[i],
      });
    }
    return row;
  }
  return (
    <>
      {data.map((data, index) => (
        <div className={Styles.row} key={index}>
          <div>{data.title}</div>
          <div className={Styles.number}>
            {data.totalCount.toLocaleString()}
          </div>
        </div>
      ))}
    </>
  );
};

export default TableRow;
