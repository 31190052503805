import React from "react";
import { useEffect, useState } from "react";
import Style from "./LanguageSelector.module.css";
const LanguageSelector = () => {
  let [language, setLanguage] = useState("ko");
  useEffect(() => {
    let lang = localStorage.getItem("i18nextLng");
    if (lang) {
      setLanguage(lang);
    }
  }, []);

  let [list, setList] = useState(["ko", "en", "ja"]);
  let changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    window.location.reload();
  };
  let [isopen, setIsopen] = useState(false);
  let [mozi, setMozi] = useState("한국어", "English", "日本語");

  return (
    <div className={Style.selector}>
      {isopen ? (
        <div>
          {list.map((lang) => {
            return (
              <img
                onClick={() => {
                  changeLanguage(lang);
                }}
                src={process.env.PUBLIC_URL + lang + ".png"}
                alt=""
                className={Style.image}
              ></img>
            );
          })}
        </div>
      ) : (
        <div className={Style.column}>
          <img
            onClick={() => {
              setIsopen(true);
            }}
            src={process.env.PUBLIC_URL + language + ".png"}
            alt=""
            className={Style.image}
          ></img>
          <div className={Style.mozi}>
            {language === "ko"
              ? "한국어"
              : language === "en"
              ? "English"
              : "日本語"}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
