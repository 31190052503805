import Styles from "./Status.module.css";
import React from "react";
import Contents from "../Layout/Contents";
function Status(props) {
  return (
    <Contents
      component={
        <div className={Styles.status}>
          <div className={Styles.main}>
            {props.title[0]}/{props.title[1]}
          </div>
          <div className={Styles.sub}>
            {props.data[0]}/{props.data[1]}
          </div>
        </div>
      }
    />
  );
}

export default Status;
