import SimpleLineChart from "../../../Chart/SimpleLineChart";
import ContentsHeader from "../../../Contents/Layout/ContentsHeader";
import Contentsbody from "../../../Contents/Layout/Contentsbody";
import Styles from "./Compare.module.css";
import React from "react";
function Compare(props) {
  return (
    <>
      <div style={{ width: "100%" }}>
        <Contentsbody
          style={{ justifyContent: "space-around" }}
          component={
            <>
              <div
                className={`${Styles.rowContents} ${Styles.btween} ${Styles.center}`}
                style={{ padding: "3% 4%" }}
              >
                <div className={Styles.name}>{props.data.title}</div>
                <div>
                  <div
                    className={`${Styles.mcount} ${
                      props.data.diffCount < 0 || props.data.diffPercent < 0
                        ? Styles.minuscolor
                        : Styles.pluscolor
                    }`}
                  >
                    {props.data.totalCount.toLocaleString()}
                  </div>
                  <div className={`${Styles.rowContents} ${Styles.right} `}>
                    <div
                      className={`${
                        props.data.diffCount < 0
                          ? Styles.minuscolor
                          : Styles.pluscolor
                      } ${Styles.scount}`}
                    >
                      {props.data.diffCount > 0 && "+"}
                      {props.data.diffCount.toLocaleString()}
                    </div>
                    <div
                      className={`${
                        props.data.diffPercent < 0
                          ? Styles.minuscolor
                          : Styles.pluscolor
                      } ${Styles.scount}`}
                    >
                      {props.data.diffPercent > 0 && "+"}
                      {props.data.diffPercent}%
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
}

export default Compare;
