import Styles from "./Subcontents.module.css";
import React from "react";
function Submidlecontents(props) {
  return (
    <>
      <div className={Styles.submenu} style={props.style}>
        {props.component}
      </div>
    </>
  );
}

export default Submidlecontents;
