import React, { useEffect } from "react";
import DashBoard from "./contents/DashBoard.jsx";
import Submenu from "../../Contents/Layout/Submenu.jsx";
import { useState } from "react";
import Body from "../../Layout/Body/Body.jsx";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const WorldReport = () => {
  const {t} = useTranslation();
  const [선택된메뉴, 선택된메뉴변경] = useState([0, 0, 0]);
  let 월드목록 = useSelector((state) => {
    return state.월드목록;
  });
  let [검색할월드, 검색할월드변경] = useState([]);
  useEffect(() => {
    CallworldList(월드목록);
    fetchData(0);
    // eslint-disable-next-line
  }, [월드목록]);
  useEffect(() => {
    let Select = 선택된메뉴[0];
    fetchData(Select);
    // eslint-disable-next-line
  }, [선택된메뉴]);
  let [menu, menuSet] = useState({
    World: [t("None")],
    Date: [t("None"), t("day"), t("week"), t("month"),t("Year")],
  });
  function fetchData(Select) {
    if (Select === 0) {
      검색할월드변경(월드목록);
    } else {
      Select -= 1;
      검색할월드변경([월드목록[Select]]);
    }
  }

  function CallworldList(data) {
    if (data) {
      let worldName = data.map((item) => item.worldName);
      worldName.unshift(t("None")); //맨 앞열에 None 추가
      menuSet({ ...menu, World: worldName });
    }
  }
  return (
    <>
      <Submenu
        menu={menu}
        SelectedMenu={선택된메뉴}
        SelectedMenuChange={선택된메뉴변경}
      />
      <Body
        component={
          <>
            {검색할월드.length !== 0 &&
              검색할월드.map((element, index) => {
                return (
                  <DashBoard
                    key={index}
                    worldId={element.worldId}
                    date={선택된메뉴[1]}
                  />
                );
              })}
          </>
        }
      />
    </>
  );
};

export default WorldReport;
