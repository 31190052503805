import React from "react";
import Styles from "./CreatorProfile.module.css";
import { useTranslation } from "react-i18next";

const CreatorProfile = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={Styles.body}>
        <div
          style={{
            fontWeight: "bold",
            textShadow: "0px 0px 10px #fff",
          }}
        >
          Create By Team Alice
        </div>
      </div>
      <img
        src={process.env.PUBLIC_URL + "logo.png"}
        alt=""
        style={{ width: "80px" }}
      ></img>
      <div className={Styles.body}>
        <div
          style={{
            fontWeight: "bold",
            textShadow: "0 0 4px #FFFFFF, 0 0 7px #1205B1, 0 0 9px #5CD2FC",
          }}
        >
          Alice Manager
        </div>
      </div>
      <br></br>

      <div className={Styles.body}>
        <div style={{ fontWeight: "bold" }}>{t("Developer")}</div>
      </div>
      <img
        src={process.env.PUBLIC_URL + "yosuzume.png"}
        alt=""
        style={{
          width: "40px",
          borderRadius: "50%",
          border: "1px solid #fff",
          boxShadow: "0px 0px 10px #fff",
        }}
      ></img>
      <div className={Styles.body}>
        <div style={{ fontWeight: "bold", textShadow: "0px 0px 10px #fff" }}>
          yosuzume
        </div>
      </div>
      <br></br>
    </>
  );
};

export default CreatorProfile;
