import React from "react";
import Contents from "../../Contents/Layout/Contents";
import Styles from "./Help.module.css";
import { useTranslation } from "react-i18next";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Image from "./contents/Image.tsx";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader";
import CreatorProfile from "../../Contents/CreatorProfile/CreatorProfile.jsx";

const Help = () => {
  const { t, i18n } = useTranslation();
  return (
    <Contents
      component={
        <>
          <ComponentHeader title={"Help"} />
          <CreatorProfile />
          <div className={Styles.rowcenter}>
            <div className={Styles.index}>{t("What is this?")}</div>
            <div className={Styles.body}>
              <li>
                {t(
                  "This Application is a tool that helps creators create worlds in"
                )}
              </li>
              <li>
                {t(
                  "This is to enable a detailed view of visitors' statistics, including time-based increases, in your world."
                )}
              </li>
              <li>
                {t(
                  "The aim is to closely examine update cycles and visitor responses within your world."
                )}
              </li>
              <li>
                {t(
                  "Through this, it is hoped that motivation for creation and reduction of production fatigue through strategic updates can be achieved."
                )}
              </li>
            </div>
            <div className={Styles.index}>{t("Set up")}</div>

            <div className={`${Styles.numberlist}` + " " + `${Styles.body}`}>
              <p>{t("To use this tool, there are several steps involved.")}</p>
              <Image src={"tutorial1.png"} />

              <li>
                {t(
                  "First, add a world in the World Create tab. Then, remember the world code."
                )}
              </li>
              <p>
                {t(
                  "Remember! The world code should never be disclosed to the public."
                )}
              </p>

              <li>
                {t(
                  "Second, add the World Analyzer, available from Booth and Github, to your Unity package."
                )}
              </li>
              <p>
                {t(
                  "This asset utilizes a simple gimmick using Vrchat's String Loader, but you can also create it yourself."
                )}
              </p>
              <Image src={"tutorial2.png"} />

              <li>
                {t(
                  "Add the Udon World Manager.Prefab from Project ALice to the scene."
                )}
              </li>
              <li>
                {t("Attach the World Analyzer script to the Target Script.")}
              </li>
              <li>{t("Enter the world code in World Code.")}</li>
              <li>
                {t(
                  "For testing, press Server Connection Test and World Connection Test sequentially."
                )}
              </li>
              <li>{t("If both show Connect, it is normal.")}</li>
              <li>
                {t("If an issue occurs, follow the actions listed below.")}
              </li>
              <li>
                {t(
                  "After the test, press the Apply World Code button. If the URL of the World Analyzer script at the bottom changes, it is successful."
                )}
              </li>

              <li>{t("upload your world. That's it. It's very simple!")}</li>
            </div>
            <div className={Styles.index}>{t("How can i test?")}</div>
            <div className={`${Styles.numberlist}` + " " + `${Styles.body}`}>
              <li>{t("After setup, enter play mode and wait for loading")}</li>
              <Image src={"tutorial3.png"} />

              <li>
                {t(
                  `If \"Connection completed!\" message appears in the Console, it means registration is successful.`
                )}
              </li>
              <li>
                {t(
                  "For detailed confirmation, check the log list window of this app. If records are added, it means the connection is normal."
                )}
              </li>
              <p>{t("Exceptions")}</p>
              <p>
                {t(
                  "If nothing appears in the Console or no logs are added to this app's log window,"
                )}
              </p>
              <li>{t("First, check if the WorldCode matches exactly.")}</li>
              <li>{t("Second, verify the URL format.")}</li>
              <li>
                {t(
                  "Third, check the server-related status in the app's notification tab."
                )}
              </li>
            </div>
            <div className={Styles.index}>{t("One More")}</div>

            <div className={Styles.body}>
              <p>
                {t(
                  "Even if this app is functioning properly, the server for logging might not be operational."
                )}
                <br />
                <br />
                {t(
                  "In such cases, we have to wait for our team's intervention."
                )}
                <br /> <br />
                {t(
                  "Since this app is implemented based on MSA architecture, each function has a separate server."
                )}
                <br /> <br />
                {t(
                  "Therefore, some services may not work due to updates or other reasons, so please refer to the notification tab in such cases."
                )}
              </p>
              <br />
              <br />
              <p>
                {t(
                  "Please note that currently, the game user and save code tabs are not accessible to anyone other than a few testers."
                )}
                <br /> <br />
                {t(
                  "These features are under development and improvement for testing and convenience, so please be patient."
                )}
                <br />
                <br />
                {t(
                  "If you wish to participate in testing, please message us through the Inquiry tab."
                )}
                <br />
                <br />
                {t(
                  "However, if there is no active cooperation, we will not provide access to these beta features."
                )}
              </p>
              <br />
            </div>

            <Neonbutton
              text="Return"
              onClick={() => {}}
              style={{ width: "80%", margin: "10px 0px" }}
            />
            <br />
          </div>
        </>
      }
    />
  );
};

export default Help;
