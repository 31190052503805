import React, { useState } from "react";
import Contents from "../../Contents/Layout/Contents";
import Styles from "./ContactUs.module.css";
import ImageHeader from "../AccountSetting/contents/ImageHeader.tsx";
import { useTranslation } from "react-i18next";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Contentsbody from "../../Contents/Layout/Contentsbody.jsx";
import { SendToMessage } from "./ContactUsAxios.js";
import { 알림내용변경, 알림변경 } from "../../redux/store/store.js";
import { useDispatch } from "react-redux";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader";
import CreatorProfile from "../../Contents/CreatorProfile/CreatorProfile.jsx";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({ fromEmail: "", subject: "", content: "" });
  const [isActive, setIsActive] = useState(false);
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    //email 정규식
    //subject 정규식 2글자 이상
    //message 정규식 2글자 이상
    if (
      form.fromEmail.match(
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
      ) &&
      form.subject.length > 2 &&
      form.content.length > 2
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  return (
    <Contents
      component={
        <>
          <ComponentHeader title={"Contact Us"} />
          <CreatorProfile />

          <div className={Styles.rowcenter}>
            <ImageHeader title="Team Alice" src="WorldManager.ico" />
            <div className={Styles.body}>
              <li>
                {t(
                  "Team Alice complies with the community guidelines of VRchat."
                )}
              </li>
              <li>
                {t(
                  "Team Alice operates solely on donations as a non-profit group and does not generate revenue."
                )}
              </li>

              <li>
                {t("This application is created to support all creators.")}
              </li>
              <li>
                {t(
                  "We apologize, but we do not accept advertising or billing inquiries."
                )}
              </li>
            </div>
            <ImageHeader title={t("Comment")} src="WorldManager.ico" />
            <div className={Styles.body}>
              <p>{t("Hello. My name is yosuzume, a full-stack developer.")}</p>
              <p>{t("I'm also a user who enjoys VRchat.")}</p>
              <p>
                {t(
                  "I thought this project could help both the world creators a little."
                )}
              </p>
              <p>
                {t(
                  "Please show a lot of interest, and please look forward to the next updates!"
                )}
              </p>

              <p>{t("if you have any questions, please contact us.")}</p>
              <p>{t("Thank you.")}</p>
            </div>
            <ImageHeader title={t("Support")} src="WorldManager.ico" />
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
                padding: "5px",
              }}
            >
              <p>{t("If you want support our Team then")}</p>
              <img
                style={{ width: "40px", padding: "20px 20px" }}
                src={process.env.PUBLIC_URL + "GitHub.svg"}
                onClick={() => {
                  window.open(
                    "https://github.com/rdcyosuzume/UdonWorldManager_Unity"
                  );
                }}
                alt=""
              ></img>
              <p>{t("Support translation")}</p>
              <p>
                {t(
                  "If you follow our account or give us a star, it's a big cheer."
                )}
              </p>
              <img
                style={{
                  width: "40px",
                  padding: "20px 20px",
                }}
                src={process.env.PUBLIC_URL + "Patreon.svg"}
                alt=""
                onClick={() => {
                  window.open("https://www.patreon.com/yosuzume");
                }}
              ></img>
              <p>{t("Support Patreon")}</p>
              <p>
                {t(
                  "Donations will be used to expand and maintain the server, improve services, and add functions."
                )}
              </p>
            </div>

            <ImageHeader title={t("Social Contact")} src="WorldManager.ico" />
            <Contentsbody
              component={
                <>
                  <img
                    style={{ width: "40px", padding: "20px 20px" }}
                    src={process.env.PUBLIC_URL + "Twitter.png"}
                    alt=""
                    onClick={() => {
                      window.open(
                        "https://twitter.com/OeRJ0qA9MKCK2WF",
                        "_blank"
                      );
                    }}
                  ></img>
                  <img
                    style={{ width: "40px", padding: "20px 20px" }}
                    src={process.env.PUBLIC_URL + "discord.png"}
                    alt=""
                    onClick={() => {
                      window.open("https://discord.gg/ZdmG9YPgMF", "_blank");
                    }}
                  ></img>
                </>
              }
            />

            <ImageHeader title={t("Send To Message")} src="WorldManager.ico" />
            <div className={Styles.body} style={{ width: "90%" }}>
              <div style={{ margin: "10px 0px" }}>{t("Email")}</div>
              <input
                className={i18n.language}
                style={{
                  height: "24px",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  padding: "10px 10px",

                  width: "95%",
                  fontWeight: "100",
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
                name="fromEmail"
                value={form.fromEmail}
                onChange={(e) => handleChange(e)}
                placeholder="No-reply@mystialolelei.site"
              ></input>
              <div style={{ margin: "10px 0px" }}>{t("Subject")}</div>
              <input
                className={i18n.language}
                style={{
                  height: "24px",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  padding: "10px 10px",
                  width: "95%",
                  fontWeight: "100",
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
                name="subject"
                value={form.subject}
                onChange={(e) => handleChange(e)}
                placeholder={t("Bugreport")}
              ></input>
              <div style={{ margin: "10px 0px" }}>{t("Message")}</div>

              <textarea
                className={i18n.language}
                style={{
                  padding: "10px",
                  color: "#fff",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  border: "none",
                  height: "100px",
                  fontSize: "18px",
                  resize: "none",
                  borderRadius: "5px",
                }}
                name="content"
                value={form.content}
                onChange={(e) => handleChange(e)}
                placeholder={t("Please enter your message here.")}
              ></textarea>
            </div>
            <Neonbutton
              text={t("Send Message")}
              onClick={() => {
                setIsActive(false);
                SendToMessage(form).then((res) => {
                  if (res.data.header.code === "TOKEN_EXPIRED") return;
                  handleShowToast(res.data.header.status, res.data.header.code);
                  if (res.data.header.code === "MAIL_SEND_SUCCESS") {
                    setIsActive(true);
                    //form 초기화
                    setForm({ fromEmail: "", subject: "", content: "" });
                    //input 초기화
                  } else {
                  }
                });
              }}
              style={{ width: "80%", margin: "10px 0px" }}
              isActive={isActive}
            />
          </div>
        </>
      }
    />
  );
};

export default ContactUs;
