import React from "react";
import Contents from "../../Contents/Layout/Contents";
import Styles from "./Rules.module.css";
import ImageHeader from "../AccountSetting/contents/ImageHeader.tsx";
import { useTranslation } from "react-i18next";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import { useNavigate } from "react-router-dom";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader";
import CreatorProfile from "../../Contents/CreatorProfile/CreatorProfile.jsx";

const Rules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Contents
      component={
        <>
          <ComponentHeader title={"Terms for use"} />
          <CreatorProfile />
          <div className={Styles.rowcenter}>
            <ImageHeader title="Team Alice" src="WorldManager.ico" />
            <div className={Styles.body}>
              <li>
                {t(
                  "Team Alice complies with the community guidelines of VRchat."
                )}
              </li>
              <li>
                {t(
                  "Team Alice operates solely on donations as a non-profit group and does not generate revenue."
                )}
              </li>

              <li>
                {t("This application is created to support all creators.")}
              </li>
              <li>
                {t(
                  "We apologize, but we do not accept advertising or billing inquiries."
                )}
              </li>
            </div>
            <ImageHeader title={t("Terms for use")} src="WorldManager.ico" />
            <div className={Styles.body}>
              <img
                src={process.env.PUBLIC_URL + "Agree.svg"}
                alt=""
                style={{ width: "40px" }}
              ></img>
            </div>
            <div className={Styles.body}>
              <div
                style={{
                  fontWeight: "bold",
                  textShadow: "0px 0px 10px #75FB4C",
                  color: "#75FB4C",
                }}
              >
                {t("Available")}
              </div>
            </div>
            <div className={Styles.body} style={{ width: "95%" }}>
              <li>
                {t("Someone who uses it for purposes other than company tasks")}
              </li>
              <li>{t("Not for profit generation")}</li>
              <li>{t("For personal projects")}</li>
            </div>

            <div className={Styles.body}>
              <img
                src={process.env.PUBLIC_URL + "Block.svg"}
                alt=""
                style={{ width: "40px" }}
              ></img>
            </div>
            <div className={Styles.body}>
              <div
                style={{
                  fontWeight: "bold",
                  textShadow: "0px 0px 10px #FE6C6C",
                  color: "#FE6C6C",
                }}
              >
                {t("unavailable")}
              </div>
            </div>
            <div className={Styles.body} style={{ width: "95%" }}>
              <li>{t("A person who uses it for company work")}</li>
              <li>{t("A person who uses a company-affiliated project")}</li>
              <li>
                {t("A person who uses it for profit-generating purposes")}
              </li>
              <li>{t("Users of other criminal or political purposes")}</li>
              <li>{t("VRchat Community Law Breakers")}</li>
              <li>{t("an impersonator of a chief administrator")}</li>
            </div>
            <div className={Styles.body}>
              <div
                style={{ fontWeight: "bold", textShadow: "0px 0px 10px #fff" }}
              >
                {t("Common rules")}
              </div>
            </div>
            <div className={Styles.body}>
              <li>
                {t(
                  "We don't have any responsibility for any damage caused by using this application."
                )}
              </li>
              <li>
                {t(
                  "If you violate the terms and conditions of use, your account will be suspended or your data will be deleted without prior warning"
                )}
              </li>
              <li>
                {t(
                  "All users of this application are considered to agree to the terms of service."
                )}
              </li>
            </div>
            <br></br>

            <Neonbutton text="Back" onClick={() => navigate(-1)} />
            <br></br>
            <br></br>
          </div>
        </>
      }
    />
  );
};

export default Rules;
