import React from "react";
import CustomTableP from "./CustomTableP";
import CustomTableM from "./CustomTableM";
import Styles from "./CustomTable.module.css";

function CustomTable(props) {
  return (
    <>
      <div className={Styles.pc}>
        <CustomTableP {...props} />
      </div>
      <div className={Styles.mobile}>
        <CustomTableM {...props} />
      </div>
    </>
  );
}

export default CustomTable;
