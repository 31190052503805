import React, { useEffect } from "react";
import Contents from "../Contents/Layout/Contents.jsx";
import ContentsHeader from "../Contents/Layout/ContentsHeader.jsx";
import Contentstitle from "../Contents/Layout/Contentstitle.jsx";
import Subcontents from "../Contents/Layout/Subcontents.jsx";
import LineInput from "../Common/Input/LineInput.tsx";
import TextButton from "../Common/button/TextButton.jsx";
import Rightalignform from "../Contents/Layout/Rightalignform.jsx";
import Neonbutton from "../Common/button/Neonbutton.tsx";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  로그인여부변경,
  로그인후메뉴,
  알림내용변경,
  알림변경,
  게스트메뉴,
} from "../redux/store/store.js";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../Layout/ComponentHeader/ComponentHeader.jsx";

const LoginPage = () => {
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };
  useEffect(() => {
    // Oauth 로그인 처리
    //url 에서 accessToken이 있으면 accessToken을 localStorage에 저장후 로그인처리
    const url = new URL(window.location.href);
    const accessToken = url.searchParams.get("accessToken");
    const userID = url.searchParams.get("user");
    const nickName = url.searchParams.get("nickName");
    if (accessToken != null) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("nickname", nickName);
      localStorage.setItem("user", userID);
      dispatch(로그인여부변경(true));
      dispatch(로그인후메뉴());

      navigate("/DashBoard");
    }
  }, []);
  const { t } = useTranslation();
  let dispatch = useDispatch();
  // Component logic goes here
  const navigate = useNavigate();
  const goToSign = () => {
    navigate("/Join");
  };

  const findPassword = () => {
    navigate("/PasswordFind");
  };

  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");

  const gotoGithubAuth = async () => {
    // 깃허브 로그인 프로세스
    await navigate("/");
    window.location.href = "/api/auth/authorize/github";
  };
  const gotogoogleAuth = async () => {
    // 구글 로그인 프로세스
    await navigate("/");
    window.location.href = "/api/auth/authorize/google";
  };

  const Login = (e) => {
    // 로그인 이벤트
    e.preventDefault();
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      email: nickname,
      password: password,
    };
    (async () => {
      let apiRes = null;
      try {
        apiRes = await axios
          .post("/api/signin", data, axiosConfig)
          .then((response) => {
            // 로그인 메인 로직
            if (response.data.header.code === "USER_LOGIN_SUCCESS") {
              handleShowToast(
                response.data.header.status,
                response.data.header.code
              );
              const { accessToken } = response.data.data.token;
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${accessToken}`;
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("nickname", response.data.data.nickName);
              localStorage.setItem("user", response.data.data.idx);
              if (response.data.data.authority.name === "Guest") {
                localStorage.setItem("authority", "Guest");
                //이메일 인증이 안된 계정의 경우 의 프로세스
                //메뉴에서 표시는 Help,ContactUs, PrivacyPolicy, Rules,JoinCheck
                dispatch(게스트메뉴());
                dispatch(로그인여부변경(true));
                navigate("/JoinCheck");
              } else {
                dispatch(로그인후메뉴());
                dispatch(로그인여부변경(true));
                navigate("/DashBoard");
              }
            }
          });
      } catch (e) {
        apiRes = e.response;
        handleShowToast(apiRes.data.header.status, apiRes.data.header.code);
      }
    })();
  };
  return (
    <>
      <Contents
        component={
          <>
            <ComponentHeader title={t("Login")} />
            <ContentsHeader component={<Contentstitle title={t("Login")} />} />
            <Subcontents
              component={
                <>
                  <LineInput
                    label={t("Email")}
                    placeholder={t("Enter Your Email")}
                    type={"text"}
                    onChange={(e) => setNickname(e.target.value)}
                  />
                  <LineInput
                    label={t("Password")}
                    placeholder={t("Enter Your PassWord")}
                    type={"password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </>
              }
            />
            <Rightalignform
              component={
                <TextButton
                  text={t("forgot password?")}
                  onClick={findPassword}
                />
              }
            />

            <Subcontents
              component={
                <>
                  <Neonbutton text={t("Login")} onClick={Login} />
                  <br />
                  <TextButton text={t("Sign Up")} onClick={goToSign} />
                  <br />
                  <div style={{ display: "flex;" }}>
                    <img
                      src={process.env.PUBLIC_URL + "GitHub.svg"}
                      alt=""
                      style={{ width: "40px", marginRight: "20px" }}
                      onClick={gotoGithubAuth}
                    ></img>
                    <img
                      src={process.env.PUBLIC_URL + "Google.svg"}
                      alt=""
                      style={{ width: "40px" }}
                      onClick={gotogoogleAuth}
                    ></img>
                  </div>

                  <br />
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default LoginPage;
