import client from "../../js/axiosConfig";

function callworldList() {
  return client.get("/worldlist/list/1?size=30").then((response) => {
    return response.data;
  });
}

function WorldDelete(Index) {
  return client.delete(`/worldlist?id=${Index}`);
}
function getWorldCode() {
  return client.get("/worldlist/worldcode").then((response) => {
    return response.data;
  });
}

function CreateNewWorld(WorlName) {
  return getWorldCode().then((response) => {
    let worldCode = response.data.worldCode;
    return client
      .post("/worldlist", {
        worldname: WorlName,
        worldcode: worldCode,
      })
      .then((apiRes) => {
        console.log("axios");
        return apiRes;
      })
      .catch((e) => {
        return e.response;
      });
  });
}

function getUsage(userIdx) {
  return client.get("/user/usage" + userIdx).then((response) => {
    return response.data;
  });
}

export { callworldList, WorldDelete, getWorldCode, CreateNewWorld, getUsage };
