import React from "react";
import Styles from "./ImageHeader.module.css";

type headerElement = {
  title: string;
  src: string;
};

const ImageHeader = ({ title, src }: headerElement) => (
  <div className={Styles.header}>
    <div className={Styles.center}>
      <img
        src={process.env.PUBLIC_URL + src}
        alt=""
        style={{ width: "24px", paddingRight: "5px" }}
      />
      <h1>{title}</h1>
    </div>
  </div>
);

export default ImageHeader;
