import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";

const SimpleAreaChart = (props) => {
  let [series, series변경] = useState([]);

  useEffect(() => {
    let temp = [];
    if (props.data !== undefined) {
      for (let i = 0; i < props.data.time.length; i++) {
        temp.push([props.data.time[i], props.data.value[i]]);
      }
      let result = [{ name: "Visitor", data: temp }];
      series변경(result);
    }
  }, [props.data]);
  let options = {
    chart: {
      type: "area",
      dropShadow: {
        enabled: true,
        top: 10,
        left: 5,
        blur: 5,
        opacity: 0.2,
      },
      height: 250,
      background: "transparent",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#00BAEC"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    theme: {
      mode: "dark",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 4,
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: "#8e8da4",
          fontSize: "14px",
          fontWeight: 100,
        },
        offsetY: 0,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      show: false,
    },
    yaxis: {
      tickAmount: 4,
      floating: false,

      labels: {
        style: {
          colors: "#8e8da4",
        },
        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      yaxis: {
        lines: {
          offsetX: -30,
        },
      },
      padding: {
        left: 20,
      },
    },
  };

  return (
    <div style={{ textAlign: "center", width: "95%" }}>
      {props.data === undefined ? (
        <img
          style={{ width: "50%", height: "50%" }}
          src={process.env.PUBLIC_URL + "Nodata.svg"}
          alt={""}
        />
      ) : (
        <Chart options={options} series={series} type="area" height={230} />
      )}
    </div>
  );
};

export default SimpleAreaChart;
