import React, { useEffect, useState } from "react";
import Styles from "./AccountSetting.module.css";
import Contentsbody from "../../Contents/Layout/Contentsbody.jsx";
import ImageHeader from "./contents/ImageHeader.tsx";
import ImageButton from "./contents/ImageButton.tsx";
import Contents from "../../Contents/Layout/Contents.jsx";
import {
  getMyprofile,
  deleteMyprofile,
  deleteWorldData,
  userUdate,
} from "./AccountSettingAxios.ts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  로그인여부변경,
  로그인전메뉴,
  알림내용변경,
  알림변경,
} from "../../redux/store/store.js";
import { useTranslation } from "react-i18next";
import Popup from "./contents/popup.tsx";
import ContentsHeader from "../../Contents/Layout/ContentsHeader.jsx";
import Contentstitle from "../../Contents/Layout/Contentstitle.jsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import InputPopup from "./contents/InputPopup.tsx";
import LineInput from "../../Common/Input/LineInput.tsx";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader";

type MyProfile = {
  email: string;
  password: string;
  nickName: string;
  worlddata?: worlddata[];
  provider?: string;
};
interface worlddata {
  worldName: string;
  worldIdx: number;
  data?: Number;
}

type PopupProps = {
  components?: React.JSX.Element;
  delevent: () => void;
  title?: string;
};

type inputForm = {
  nickname?: string;
  pastpassword?: string;
  password?: string;
  checkpassword?: string;
};
type AlertData = {
  title: string;
  placeholder: string;
  type: string;
  value: string;
  target: string;
};
function AccountSetting() {
  const [errors, setErrors] = useState({
    password: "",
    checkpassword: "",
  });
  const { t } = useTranslation();
  const [popupData, setPopupData] = useState<PopupProps>();
  let [popup, setPopup] = useState<boolean>(false);
  let [InputPopupState, setInputPopupState] = useState(false);
  const setInputPopupStateHandler = (state: boolean) => {
    setErrors({ password: "", checkpassword: "" });
    setInputPopupState(state);
  };
  const [form, setForm] = useState<inputForm>({
    nickname: "",
    password: "",
    checkpassword: "",
  });
  const [alertdata, setAlertdata] = useState<AlertData>({
    title: "",
    placeholder: "",
    type: "",
    value: "",
    target: "",
  });
  const validatePassword = (password) => {
    const passwordReg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{10,32}$/;
    if (!passwordReg.test(password)) {
      return "Password must be at least 10 ~ 32 characters long and must contain a special character";
    }
    return "";
  };

  const validatePasswordCheck = (password, passwordCheck) => {
    if (password !== passwordCheck) {
      return "Password does not match";
    }
    return "";
  };
  const handleShowToast = (code: number, text: string) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };
  let exitEvent = () => {
    setPopup(false);
  };
  function alertPopup(
    component: React.JSX.Element,
    delevent: () => void,
    title: string
  ) {
    setPopupData({ components: component, delevent: delevent, title: title });
    setPopup(true);
  }

  async function deleteEvent() {
    await deleteAccount();
    setPopup(false);
  }
  async function deleteWorldDataEvent(worldIdx: number) {
    const result = await deleteWorldData(worldIdx);
    if (result) {
      handleShowToast(200, "LOG_DELETE_SUCCESS");
    } else {
      handleShowToast(500, "INTERNAL_SERVER_ERROR");
    }
    setPopup(false);
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [guest, setGuest] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("authority") &&
      localStorage.getItem("authority") === "Guest"
    ) {
      setGuest(true);
      // return;
    }
    getMyprofile().then((data) => {
      if (data) {
        handleShowToast(200, "USER_INFO_READ_SUCCESS");
        setUserinfo(data);
      }
    });
  }, []);
  const deleteAccount = async () => {
    const result = await deleteMyprofile();

    if (result) {
      handleShowToast(result.data.header.status, result.data.header.code);
    } else {
      return;
    }
    if (result.data.header.status === 200 && result) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("nickname");
      dispatch(로그인여부변경(false));
      dispatch(로그인전메뉴({}));
      navigate("/");
    }
  };

  const [userinfo, setUserinfo] = useState<MyProfile>({
    email: "",
    password: "",
    nickName: "",
  });

  const NicknameUpdate = () => {
    // 닉네임 변경 이벤트
    clearForm();
    setAlertdata({
      title: "Nickname Update",
      placeholder: "Enter Your New Nickname",
      target: "Nickname",
      type: "text",
      value: "",
    });
    setInputPopupState(true);
  };

  const PasswordUpdate = () => {
    // 비밀 번호 변경 이벤트
    clearForm();
    setAlertdata({
      title: "Password Update",
      placeholder: "Enter Your New Password",
      type: "password",
      target: "New Password",
      value: "",
    });
    setInputPopupState(true);
  };
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });

    let error = "";
    switch (e.target.name) {
      case "password":
        error = validatePassword(e.target.value);
        break;
      case "checkpassword":
        error = validatePasswordCheck(form.password, e.target.value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [e.target.name]: error });
  };
  const handleFormSubmit = () => {
    if (alertdata.title === "Nickname Update") {
      if (
        (form.nickname ?? "").length < 4 ||
        (form.nickname ?? "").length > 10
      ) {
        handleShowToast(400, "NICKNAME_LENGTH_ERROR");
        return;
      }

      userUdate({ nickName: form.nickname }).then((data) => {
        //닉네임 업데이트
        if (data) {
          handleShowToast(data.data.header.status, data.data.header.code);
          if (data.data.header.status === 200) {
            setUserinfo({ ...userinfo, nickName: form.nickname });
            localStorage.setItem("nickname", form.nickname);
            setInputPopupState(false);
          }
        }
      });
    }
    if (alertdata.title === "Password Update") {
      if ((form.password ?? "").length < 8) {
        handleShowToast(400, "PASSWORD_LENGTH_ERROR");
        return;
      }
      if ((form.password ?? "") !== (form.checkpassword ?? "")) {
        handleShowToast(400, "PASSWORD_CHECK_ERROR");
        return;
      }
      userUdate({
        // 비밀번호 업데이트
        password: form.password,
        passwordCheck: form.pastpassword,
      }).then((data) => {
        if (data) {
          handleShowToast(data.data.header.status, data.data.header.code);
          if (data.data.header.status === 200) {
            setInputPopupState(false);
          }
        }
      });
    }
  };

  const clearForm = () => {
    setForm({ nickname: "", password: "", checkpassword: "" });
  };

  return (
    <>
      <Contents
        component={
          <>
            <ComponentHeader title={"Account Setting"} />
            <ImageHeader title={t("Nickname")} src="Nickname.svg" />
            <div className={Styles.body}>
              <div style={{ fontWeight: "bold" }}> {userinfo.nickName}</div>
            </div>
            <ImageButton
              text={t("Edit Nickname")}
              src="Edit.svg"
              onClick={NicknameUpdate}
            />
            {userinfo.provider === undefined ? (
              <>
                <ImageHeader title={t("Email Adress")} src="Email.svg" />
                <div className={Styles.body}>
                  <div style={{ fontWeight: "bold" }}>{userinfo.email}</div>
                </div>

                <ImageHeader title={t("Password")} src="Password.svg" />
                <div className={Styles.body}>
                  <div style={{ fontWeight: "bold" }}>{userinfo.password}</div>
                </div>
                <ImageButton
                  text={t("Edit Password")}
                  src="Edit.svg"
                  onClick={PasswordUpdate}
                />
              </>
            ) : (
              <>
                <ImageHeader title={t("Account Type")} src="Profile.png" />
                <div className={Styles.body}>
                  {userinfo.provider === "GitHub" && (
                    <img
                      src={process.env.PUBLIC_URL + "GitHub.svg"}
                      alt=""
                      style={{ width: "40px" }}
                    ></img>
                  )}
                  {userinfo.provider === "Google" && (
                    <img
                      src={process.env.PUBLIC_URL + "Google.svg"}
                      alt=""
                      style={{ width: "40px" }}
                    ></img>
                  )}
                </div>
              </>
            )}

            <ImageHeader title={t("Data")} src="WorldAnalyzer.svg" />
            <Contentsbody
              component={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <table>
                      <tr>
                        <th className={Styles.thader}>{t("WorldName")}</th>
                        <th className={Styles.thader}>{t("Data")}</th>
                      </tr>
                      {userinfo.worlddata?.map((item, index) => (
                        <>
                          <tr key={index} className={Styles.row}>
                            <td>{item.worldName}</td>
                            <td>{String(item.data)}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className={Styles.underline}>
                              <div className={Styles.rowcenter}>
                                <ImageButton
                                  text={t("Data Reset")}
                                  src="Delete.svg"
                                  onClick={() =>
                                    alertPopup(
                                      <>
                                        <div
                                          style={{
                                            width: "95%",
                                            textAlign: "center",
                                          }}
                                        >
                                          {t(
                                            "If you delete Data then All Data is Deleted"
                                          )}
                                        </div>
                                        <div>
                                          {t("All Data is never comeback")}
                                        </div>
                                        <div>{t("Are you sure?")}</div>
                                        <br />
                                        <div style={{ fontSize: "24px" }}>
                                          {t("World Name")}
                                        </div>
                                        <br />
                                        <div className={Styles.highlight}>
                                          {item.worldName}
                                        </div>
                                        <br />
                                      </>,
                                      () => {
                                        deleteWorldDataEvent(item.worldIdx);
                                      },
                                      "Data Reset"
                                    )
                                  }
                                  style={{
                                    color: "#EA3323",
                                    fontWeight: "bold",
                                    textShadow: "0px 0px 10px #EA3323",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </table>
                  </div>
                </>
              }
            />
            <ImageHeader title={t("Delete Account")} src="Delete.svg" />
            <img
              src={process.env.PUBLIC_URL + "Warning.svg"}
              alt=""
              style={{ width: "40px" }}
            ></img>
            <div
              style={{
                color: "#EA3323",
                fontWeight: "bold",
                textShadow: "0px 0px 10px #EA3323",
              }}
            >
              {t("Danger Zone")}
            </div>
            <div className={Styles.rowcenter}>
              <ImageButton
                text={t("Delete")}
                src="Delete.svg"
                onClick={() =>
                  alertPopup(
                    <>
                      <div>
                        {t("If you delete Account then All Data is Deleted")}
                      </div>
                      <div>{t("All Data is never comeback")}</div>
                      <div>{t("Are you sure?")}</div>
                    </>,
                    deleteEvent,
                    "Account Delete"
                  )
                }
                style={{
                  color: "#EA3323",
                  fontWeight: "bold",
                  textShadow: "0px 0px 10px #EA3323",
                }}
              />
            </div>
          </>
        }
      />
      {popup ? (
        <Popup
          components={
            <>
              <ContentsHeader
                component={<Contentstitle title={t(popupData?.title)} />}
              />

              <img
                src={process.env.PUBLIC_URL + "Warning.svg"}
                alt=""
                style={{ width: "40px" }}
              ></img>
              <div
                style={{
                  color: "#EA3323",
                  fontWeight: "bold",
                  textShadow: "0px 0px 10px #EA3323",
                }}
              >
                {t("Warning")}
              </div>
              <br />
              {popupData?.components}
              <br />
              <br />
              <Neonbutton
                text="Delete"
                onClick={() => {
                  popupData?.delevent();
                }}
              />
            </>
          }
          exitEvent={exitEvent}
        />
      ) : (
        ""
      )}
      {InputPopupState && (
        <InputPopup
          components={
            <>
              <ContentsHeader
                component={<Contentstitle title={t(alertdata?.title)} />}
              />
              <p className={Styles.alerttext}>
                {alertdata.title === "Nickname Update"
                  ? t(
                      "Nickname must be 2~10 characters without spaces or special characters"
                    )
                  : t(
                      "Password must be at least 10 ~ 32 characters long and must contain a special character"
                    )}
              </p>
              {
                // 비밀번호 변경 시에만 추가 비밀번호 입력
                alertdata.title === "Password Update" && (
                  <LineInput
                    label={t("Password")}
                    name="pastpassword"
                    value={form.pastpassword}
                    onChange={handleFormChange}
                    type="password"
                    placeholder={t("Enter Your Password")}
                  />
                )
              }
              <LineInput
                label={t(alertdata.target)}
                name={alertdata.type === "text" ? "nickname" : "password"}
                value={
                  alertdata.type === "text" ? form.nickname : form.password
                }
                onChange={handleFormChange}
                type={alertdata?.type}
                placeholder={t(alertdata?.placeholder)}
              />
              {errors.password && alertdata.type !== "text" && (
                <p className={Styles.errtext}>{t(errors.password)}</p>
              )}
              {
                // 비밀번호 변경 시에만 추가 비밀번호 입력
                alertdata.title === "Password Update" && (
                  <LineInput
                    label={t("Password Check")}
                    name="checkpassword"
                    value={form.checkpassword}
                    onChange={handleFormChange}
                    type="password"
                    placeholder={t("Enter Your New Password Again")}
                  />
                )
              }
              {errors.checkpassword && (
                <p className={Styles.errtext}>{t(errors.checkpassword)}</p>
              )}
              <br />
              <br />
              <Neonbutton text={t("Submit")} onClick={handleFormSubmit} />
            </>
          }
          exitEvent={() => setInputPopupStateHandler(false)}
        />
      )}
    </>
  );
}

export default AccountSetting;
