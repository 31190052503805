import Styles from "./ContentsHeader.module.css";
import React from "react";
function ContentsHeader(props) {
  return (
    <>
      <div className={Styles.contentsheader}>{props.component}</div>
    </>
  );
}

export default ContentsHeader;
