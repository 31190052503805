import Contents from "../../../Contents/Layout/Contents";
import Contentsbody from "../../../Contents/Layout/Contentsbody";
import Compare from "./Compare";
import Line from "../../../Common/line/Line";
import Styles from "./CompareTable.module.css";
import React, { useEffect, useState } from "react";
import TableHeader from "./TableHeader";
import { useTranslation } from "react-i18next";
function CompareTable(props) {
  const { t } = useTranslation();
  let [날짜조건, set날짜조건] = useState(0);
  let [날짜, set날짜] = useState(0);
  useEffect(() => {
    날짜조정();
  }, [props.date]);
  function 날짜조정() {
    let date = props.date;
    if (date === 0) date = 365;
    if (date === 1) date = 1;
    else if (date === 2) date = 7;
    else if (date === 3) date = 30;
    else if (date === 4) date = 365;
    set날짜조건(date);

    if (date === 1) set날짜("DAY");
    else if (date === 7) set날짜("WEEK");
    else if (date === 30) set날짜("MONTH");
    else if (date === 365) set날짜("YEAR");
  }
  return (
    <Contents
      component={
        <>
          <Contentsbody
            style={{ width: "100%" }}
            component={
              <>
                <TableHeader
                  date={props.date}
                  components={
                    <>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>{t('worldName')}</div>
                        <div style={{ fontWeight: "bold" }}>{t('Diff')}</div>
                      </div>
                      <br></br>
                      <Line
                        style={{
                          borderBottom: "0.1px solid rgba(255, 255, 255, 0.4)",
                        }}
                      />
                    </>
                  }
                />
              </>
            }
          />

          {props.data.map((element, index) => {
            return <Compare data={element} key={index} />;
          })}
        </>
      }
    />
  );
}

export default CompareTable;
