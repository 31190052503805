import Styles from "./Contentsbody.module.css";
import React from "react";
function Contentsbody(props) {
  return (
    <>
      <div className={Styles.contentsbody} style={props.style}>
        {props.component}
      </div>
    </>
  );
}

export default Contentsbody;
