import React, { useEffect, useState } from "react";
import Contents from "../../Contents/Layout/Contents.jsx";
import ContentsHeader from "../../Contents/Layout/ContentsHeader.jsx";
import Contentstitle from "../../Contents/Layout/Contentstitle.jsx";
import Subcontents from "../../Contents/Layout/Subcontents.jsx";
import LineInput from "../../Common/Input/LineInput.tsx";
import TextButton from "../../Common/button/TextButton.jsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Text from "../../Common/Text/Text.jsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendEmail, CheckCertificationCode } from "./PasswordFindAxios.js";
import { useDispatch } from "react-redux";
import { 알림변경, 알림내용변경 } from "../../redux/store/store.js";
import Styles from "./PasswordFind.module.css";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader.jsx";
const PasswordFind = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSend, setIsSend] = useState(false);
  const [timer, setTimer] = useState(180); // 3 minutes in seconds
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  useEffect(() => {
    let countdownInterval;

    if (isSend) {
      setIsButtonEnabled(false);
      countdownInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 0) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      const buttonEnableTimeout = setTimeout(() => {
        setIsButtonEnabled(true);
        setIsSend(false);
      }, 30000); // 30 seconds

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(buttonEnableTimeout);
      };
    }
  }, [isSend]);
  // Component logic goes here
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/Login");
  };
  const goToChangePassword = () => {
    navigate("/ChangePassword");
  };
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };

  const SendEmail = () => {
    // sendEmail(email);
    //email 형  정규식 검사
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegExp.test(email)) {
      handleShowToast("400", "INVALID_INPUT_VALUE");
      return;
    }
    setIsButtonEnabled(false);
    sendEmail(email).then((response) => {
      if (response.header.status === 200) {
        handleShowToast(response.header.status, response.header.code);
        setIsSend(true);
        setTimer(180); // Reset the timer to 3 minutes
      } else {
        setIsButtonEnabled(true);

        handleShowToast(response.header.status, response.header.code);
      }
    });
    // goToChangePassword();
  };

  const CheckCertification = () => {
    // code 의 자리수는 반드시 8자리 이상
    if (code.length < 8) {
      handleShowToast("400", "INVALID_INPUT_VALUE");
      return;
    }
    CheckCertificationCode(email, code).then((response) => {
      if (response.header.status === 200) {
        handleShowToast(response.header.status, response.header.code);
        localStorage.setItem("semiAccessCode", response.data.semiAccessCode);
        localStorage.setItem("user", response.data.user);
        goToChangePassword();
      } else {
        handleShowToast(response.header.status, response.header.code);
      }
    });
    // CheckCertificationCode(email, code);
  };

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  return (
    <>
      <Contents
        component={
          <>
            <ComponentHeader title={t("Find Password")} />
            <ContentsHeader
              component={<Contentstitle title={t("Find Password")} />}
            />
            <Subcontents
              style={{ textAlign: "center", width: "95%" }}
              component={
                <>
                  <br />
                  <Text text={t("are you remember the email")} />
                  <Text text={t("you entered when you created the account?")} />
                  <Text text={t("Please enter that email")} />
                  <Text
                    text={t("We'll send the Certification code to your email")}
                  />

                  <br />
                  <LineInput
                    style={{ textAlign: "center", alignItems: "center" }}
                    label={t("Email")}
                    placeholder={t("Enter Your Email")}
                    type={"Email"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  <Neonbutton
                    text={t("Confirm")}
                    onClick={SendEmail}
                    isActive={isButtonEnabled}
                  />
                  <br />
                  {isSend && (
                    <Text
                      Styleclass={Styles.timer}
                      text={`Time remaining: ${Math.floor(timer / 60)}:${String(
                        timer % 60
                      ).padStart(2, "0")}`}
                    />
                  )}
                  {isSend && (
                    <>
                      <br />
                      <Text
                        text={t(
                          "Check your email and enter the Certification code"
                        )}
                      />
                      <br />
                      <LineInput
                        style={{ textAlign: "center", alignItems: "center" }}
                        label={t("Certification Code")}
                        placeholder={t("Enter Your Certification Code")}
                        type={"text"}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </>
                  )}
                </>
              }
            />
            {isSend && (
              <>
                <br />
                <Subcontents
                  component={
                    <>
                      <Neonbutton
                        text={t("Confirm")}
                        onClick={CheckCertification}
                      />
                      <br />
                    </>
                  }
                />
              </>
            )}

            <TextButton text={t("Login")} onClick={goToLogin} />
            <br />
          </>
        }
      />
    </>
  );
};

export default PasswordFind;
