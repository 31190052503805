import Styles from "./Body.module.css";
import React from "react";
function Body(props) {
  return (
    <>
      <div className={Styles.body}>{props.component}</div>
    </>
  );
}

export default Body;
