import React from "react";
import Styles from "./Popup.module.css";
import PopupHeader from "../../../Layout/popupHeader/popupHeader";

interface PopupProps {
  components: React.JSX.Element;
  exitEvent: () => void;
}

const Popup: React.FC<PopupProps> = ({ components, exitEvent }) => {
  function stopPropagation(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
  }

  return (
    <div className={Styles.popupBackground} onClick={exitEvent}>
      <div className={Styles.popup} onClick={stopPropagation}>
        <PopupHeader exitTrigger={exitEvent} />

        {components}

        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Popup;
