import Styles from "./LeftWing.module.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  HambegerClose,
  로그인여부변경,
  로그인전메뉴,
} from "../../redux/store/store.js";
import { logoutAxios } from "../Hambeger/HambergerAxios.js";
function LeftWing(props) {
  let dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let 로그인여부 = useSelector((state) => {
    return state.로그인여부;
  });
  const logout = async () => {
    await logoutAxios();
    dispatch(HambegerClose(false));
    dispatch(로그인여부변경(false));
    dispatch(로그인전메뉴());
  };
  return (
    <>
      <ul
        className={Styles.MenuRoot}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            height: "35px",
            borderBottom: "1px solid rgba(255, 255, 255, 0.425)",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            style={{
              float: "left",
              marginLeft: "10px",
              marginTop: "7px",
              display: "flex",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "WorldManager.ico"}
              alt="Logo"
              height="20px"
              style={{ marginRight: "5px" }}
              className={i18n.language + "headerimg"}
            />
            <div style={{ fontSize: "18px" }}>{t("Menu")}</div>
          </div>
        </div>
        {props.data.map((item, index) => {
          return (
            <li
              key={index}
              className={`${Styles.MenuElement} ${
                props.location === item.path ? Styles.active : ""
              }`}
            >
              <Link to={item.path}>{t(item.title)}</Link>
            </li>
          );
        })}
        {로그인여부 && (
          <>
            <div
              className={Styles.profile}
              style={{ padding: "5px 4px 5px 12px" }}
              onClick={logout}
            >
              <img
                src={process.env.PUBLIC_URL + "logout.svg"}
                alt={""}
                style={{ width: "18px", height: "18px" }}
              />
              <p style={{ margin: "10px 8px" }}>{t("Logout")}</p>
            </div>
          </>
        )}
      </ul>
    </>
  );
}

export default LeftWing;
