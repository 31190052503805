import client from "../../js/axiosConfig";

const checkEmail = async () => {
  return await client
    .get("/SendCertification")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const checkCertification = async (data: { certification: string }) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await client
    .post("/CheckCertification", { certificationCode: data }, options)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      return error.response.data;
    });
};

export { checkCertification, checkEmail };
