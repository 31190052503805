import "./App.css";
import Header from "./Layout/Header/Header.jsx";
import Layer from "./Layout/Layer/Layer.jsx";
import WorldReport from "./Page/Index/WorldReport.jsx";
import LoginPage from "./Page/LoginPage.jsx";
import Join from "./Page/Join/Join.jsx";
import JoinCheck from "./Page/JoinCheck/JoinCheck.jsx";
import { Routes, Route } from "react-router-dom";
import Password from "./Page/PasswordFind/PasswordFind.jsx";
import ChangePassword from "./Page/PasswordFind/ChangePassword.jsx";
import Hamberger from "./Layout/Hambeger/Hamberger.jsx";
import WorldCreate from "./Page/WorldCreate/WorldCreate.jsx";
import GameUserList from "./Page/GameUserList/GameUserList.jsx";
import SavecodeList from "./Page/SavecodeList/SavecodeList.jsx";
import DashBoardPage from "./Page/DashBoard/DashBoardPage.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  월드목록변경,
  로그인후메뉴,
  로그인전메뉴,
  로그인여부변경,
  알림변경,
  알림내용변경,
  게스트메뉴,
} from "./redux/store/store.js";
import { callworldList } from "./js/DefaultAxios.js";
import "./locale/i18n.ts";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import PrivacyPolicy from "./Page/PrivacyPolicy/PrivacyPolicy.jsx";
import AccountSetting from "./Page/AccountSetting/AccountSetting.tsx";
import ContactUs from "./Page/ContactUs/ContactUs.tsx";
import Rules from "./Page/Rules/Rules.tsx";
import Footer from "./Layout/Footer/Footer.tsx";
import Body from "./Layout/Body/Body.jsx";
import Help from "./Page/Help/Help.tsx";
import LeftWing from "./Layout/LeftWing/LeftWing.jsx";
import { useLocation } from "react-router-dom";
import Toast from "./Contents/Toast/Toast.jsx";
import { useEffect } from "react";
import LogList from "./Page/LogList/LogList.jsx";
import Credit from "./Page/License/Credit.tsx";
import { Helmet } from "react-helmet-async";

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };
  const HambergerState = useSelector((state) => {
    return state.Hamberger;
  });

  let 로그인여부 = useSelector((state) => {
    return state.로그인여부;
  });
  let 알림내용 = useSelector((state) => {
    return state.알림내용;
  });
  let 알림 = useSelector((state) => {
    return state.알림;
  });
  let dispatch = useDispatch();

  const CallworldList = async () => {
    if (localStorage.getItem("accessToken") !== null) {
      if (localStorage.getItem("authority") === "Guest") {
        // 이메일 인증 안되어있을 경우 게스트로 처리
        dispatch(게스트메뉴());
        dispatch(로그인여부변경(true));
        return; // 게스트는 월드 기능을 못쓰니까 여기서 리턴
      }
      await callworldList().then((response) => {
        if (response.header.code === "INVALID_REFRESH_TOKEN") {
          handleShowToast(response.header.status, response.header.code);
          alert("로그인이 필요합니다.");
          dispatch(로그인여부변경(false));
          dispatch(로그인전메뉴());
        } else if (
          response.header.code === "WORLD_READ_SUCCESS" &&
          localStorage.getItem("authority") !== "Guest"
        ) {
          handleShowToast(response.header.status, response.header.code);
          dispatch(로그인여부변경(true));
          dispatch(월드목록변경(response.data));
          dispatch(로그인후메뉴());
        }
      });
    } else {
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i) !== "i18nextLng") {
          localStorage.removeItem(localStorage.key(i));
        }
      }
      dispatch(로그인여부변경(false));
      dispatch(로그인전메뉴());
    }
  };
  const { isLoading } = useQuery(로그인여부 + "", CallworldList, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });
  let menu = useSelector((state) => {
    return state.메뉴;
  });

  useEffect(() => {
    if (알림) {
      const timer = setTimeout(() => {
        dispatch(알림변경(false));
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [알림]);

  return (
    <div className={`App ` + `${i18n.language}`}>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>Alice Manager</title>
        <meta
          name="description"
          content="Alice Manager는 월드를 관리하는 웹 어플리케이션입니다."
        />
        <meta charSet="utf-8" />
      </Helmet>
      <Layer
        component={
          <>
            <Header />
            {HambergerState && <Hamberger data={menu} />}
            <div className="DesktopBodyLayout">
              <div className="wing">
                <LeftWing data={menu} location={location.pathname} />
              </div>
              <Body
                component={
                  <>
                    {알림 && <Toast props={알림내용} />}
                    {로그인여부 && (
                      <Routes>
                        <Route
                          path="/ChangePassword"
                          element={<ChangePassword />}
                        />
                        <Route path="/WorldReport" element={<WorldReport />} />
                        <Route path="/WorldCreate" element={<WorldCreate />} />
                        <Route
                          path="/GameUserList"
                          element={<GameUserList />}
                        />
                        <Route
                          path="/SavecodeList"
                          element={<SavecodeList />}
                        />
                        <Route path="/LogList" element={<LogList />} />
                        <Route path="/DashBoard" element={<DashBoardPage />} />
                        <Route path="/Privacy" element={<PrivacyPolicy />} />
                        <Route path="/Account" element={<AccountSetting />} />
                        <Route path="/ContactUs" element={<ContactUs />} />
                        <Route path="/Help" element={<Help />} />
                        <Route path="/Rules" element={<Rules />} />
                        <Route path="/PasswordFind" element={<Password />} />
                        <Route path="/JoinCheck" element={<JoinCheck />} />
                        <Route path="/Credit" element={<Credit />} />
                      </Routes>
                    )}
                    {!로그인여부 && (
                      <Routes>
                        <Route path="/Join" element={<Join />} />
                        <Route path="/Login" element={<LoginPage />} />
                        <Route path="/PasswordFind" element={<Password />} />
                        <Route
                          path="/ChangePassword"
                          element={<ChangePassword />}
                        />
                        <Route path="/Privacy" element={<PrivacyPolicy />} />
                        <Route path="/Help" element={<Help />} />
                        <Route path="/Rules" element={<Rules />} />
                        <Route path="/Credit" element={<Credit />} />
                        <Route path="/*" element={<LoginPage />} />
                      </Routes>
                    )}
                  </>
                }
              />
              <div className="wing"></div>
            </div>

            <Footer />
          </>
        }
      />
    </div>
  );
}

export default App;
