import Styles from "./Contentstitle.module.css";
import React from "react";
function Contentstitle(props) {
  return (
    <>
      <h1 className={Styles.contentstitle} aria-label="Content Title">
        {props.title}
      </h1>
    </>
  );
}

export default Contentstitle;
