import { t } from "i18next";
import client from "../../js/axiosConfig";

type MyProfile = {
  email: string;
  password: string;
  nickName: string;
  worlddata?: worlddata[];
  provider?: string;
};

interface worlddata {
  worldName: string;
  worldIdx: number;
  data?: Number;
}

type inputForm = {
  nickname?: string;
  password?: string;
  checkpassword?: string;
};

const getMyprofile = async () => {
  const user = localStorage.getItem("user");
  console.log(user);
  if (user === null || user === undefined || user === "NaN") {
    return;
  }

  const response = await client.get("/user" + user);
  if (response.data.header.code === "USER_INFO_READ_SUCCESS") {
    return response.data.data as MyProfile;
  }
};

const deleteMyprofile = async () => {
  const user = localStorage.getItem("user");
  if (user === null || user === undefined || user === "NaN") {
    return;
  }

  const response = await client.delete("/user" + user);
  if (response.data.header.code === "USER_DELETE_SUCCESS") {
    return response;
  }
};

const deleteWorldData = async (worldIdx: number) => {
  const response = await client.delete("world/log" + worldIdx);
  if (response.data.header.code === "LOG_DELETE_SUCCESS") {
    return true;
  } else {
    return false;
  }
};

const userUdate = async (data) => {
  const user = localStorage.getItem("user");
  if (user === null || user === undefined || user === "NaN") {
    return;
  }
  const response = await client.patch("/user" + user, data);
  return response;
};

export { getMyprofile, deleteMyprofile, deleteWorldData, userUdate };
