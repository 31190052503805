import React from "react";

import Styles from "./Image.module.css";

type ImageProps = {
  src: string;
};

const Image = ({ src }: ImageProps) => (
  <img
    src={process.env.PUBLIC_URL + src}
    className={Styles.imagesize}
    alt=""
  ></img>
);

export default Image;
