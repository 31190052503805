import React from "react";
import Submenu from "../../Contents/Layout/Submenu.jsx";
import { useState } from "react";
import {
  callAllworldTraffic,
  callAllworldTrafficCardBytime,
  getAnailerTrafficWorldVisitorRatio,
  callAllworldTrafficpercent,
} from "./DashBoardAxios.js";
import SimpleDonutChart from "../../Chart/SimpleDonutChart.jsx";
import Contents from "../../Contents/Layout/Contents.jsx";
import Contentsbody from "../../Contents/Layout/Contentsbody.jsx";
import SimpleAreaChart from "../../Chart/SimpleAreaChart.jsx";
import SimpleGrowthChart from "../../Chart/SimpleGrowthChart.jsx";
import CompareTable from "./contents/CompareTable.jsx";
import Styles from "./DashBoardPage.module.css";
import TableHeader from "./contents/TableHeader.jsx";
import TableRow from "./contents/TableRow.jsx";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  로그인여부변경,
  알림내용변경,
  알림변경,
} from "../../redux/store/store.js";
import { useTranslation } from "react-i18next";

const DashBoardPage = () => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };

  const [선택된메뉴, 선택된메뉴변경] = useState([0]);
  const fetchData = async () => {
    let date = 선택된메뉴[0];
    try {
      if (date !== 0) {
        if (date === 1) date = 1;
        else if (date === 2) date = 7;
        else if (date === 3) date = 30;
        else if (date === 4) date = 365;
        let start = new Date();
        start.setDate(start.getDate() - date);
        let values = {
          start: start.toJSON(),
          end: new Date().toJSON(),
        };
        await getAnailerTrafficWorldVisitorRatio(values).then((response) => {
          data변경(response);
        });
        await callAllworldTrafficCardBytime(values).then((response) => {
          CompareData변경(response);
        });
        await callAllworldTraffic(values).then((response) => {
          AreaChartData변경(response);
        });
        await callAllworldTrafficpercent(values).then((response) => {
          GrowthChartData변경(response);
        });
      } else {
        await getAnailerTrafficWorldVisitorRatio().then((response) => {
          data변경(response);
        });
        await callAllworldTrafficCardBytime().then((response) => {
          CompareData변경(response);
        });
        await callAllworldTraffic().then((response) => {
          AreaChartData변경(response);
        });
        await callAllworldTrafficpercent().then((response) => {
          GrowthChartData변경(response);
        });
      }
      handleShowToast(200, "WORLD_READ_SUCCESS");
    } catch (e) {
      console.log("error");
      if (localStorage.getItem("accessToken") === null) {
        alert("로그인이 필요합니다.");
        dispatch(로그인여부변경(false));
      }
    }
  };
  const { isLoading } = useQuery([선택된메뉴], fetchData, {
    cacheTime: 300000,
    refetchOnWindowFocus: false,
  });

  let [data, data변경] = useState();
  let [AreaChartData, AreaChartData변경] = useState();
  let [GrowthChartData, GrowthChartData변경] = useState();
  let [CompareData, CompareData변경] = useState([]);
  let [menu, menuSet] = useState({
    Date: [t("None"), t("day"), t("week"), t("month"), t("Year")],
  });
  return (
    <>
      <Submenu
        menu={menu}
        SelectedMenu={선택된메뉴}
        SelectedMenuChange={선택된메뉴변경}
      />
      <Contents
        title="DashBoard"
        component={
          <>
            <Contentsbody
              style={{ width: "100%" }}
              component={<TableHeader date={선택된메뉴[0]} />}
            />
            <div className={Styles.header} style={{ borderTop: "0px" }}>
              {t("Visitor Ratio")}
            </div>
            <br></br>
            <Contentsbody component={<SimpleDonutChart data={data} />} />
            <br></br>
            <div
              className={Styles.header}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div className={Styles.bold}>{t("worldName")}</div>
              <div className={Styles.bold}>{t("Number")}</div>
            </div>
            <div style={{ width: "100%" }}>
              <br></br>
              <TableRow data={data} />
            </div>
            <br></br>
          </>
        }
      />
      <Contents
        component={
          <>
            <Contentsbody
              style={{ width: "100%" }}
              component={<TableHeader date={선택된메뉴[0]} />}
            />
            <div className={Styles.header} style={{ borderTop: "0px" }}>
              {t("Visitor Increase Graph (Num)")}
            </div>
            <br></br>

            <SimpleAreaChart data={AreaChartData} />
            <br></br>

            <div className={Styles.header}>{t("Rate Of Growth (%)")}</div>
            <br></br>

            <SimpleGrowthChart data={GrowthChartData} />
            <br></br>
          </>
        }
      />
      <CompareTable data={CompareData} date={선택된메뉴[0]} />
    </>
  );
};

export default DashBoardPage;
