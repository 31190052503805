import React from "react";
import Chart from "react-apexcharts";

function SimpleStickChart(props) {
  const options = {
    responsive: [
      {
        breakpoint: 750,
        options: {
          chart: {
            height: 200,
          },
          yaxis: {
            tickAmount: 4,
            labels: {},
          },
          xaxis: {
            categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 100,
                cssClass: "mainfont300",
              },
            },
          },
        },
      },
    ],
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: -30,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    theme: {
      mode: "dark",
    },
    chart: {
      type: "line",
      dropShadow: {
        enabled: true,
        top: 10,
        left: 5,
        blur: 5,
        opacity: 0.8,
      },
      background: "transparent",
      toolbar: {
        show: false,
      },
      offsetY: 0,
      offsetX: 0,
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#fff"],
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        show: true,
        Text: {
          style: {},
        },
        style: {
          colors: [],
          fontSize: "14px",
          fontWeight: 100,
          cssClass: "mainfont300",
        },
        offsetX: -5,
        minWidth: 1,
      },
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      labels: {
        show: true,
        style: {
          colors: [],
          fontSize: "14px",
          fontWeight: 100,
          cssClass: "mainfont300",
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
      },
    },
  };
  let series = [
    {
      name: "Visitor",
      data: dataParsing(props.data),
    },
  ];
  function dataParsing(data) {
    var dayOfWeekData = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };

    // 각 데이터를 요일별로 그룹화하고 값을 더함
    data.forEach(function (item) {
      var logDate = new Date(item[0]);
      var options = { weekday: "long", timeZone: "Asia/Seoul" };
      var dayOfWeek = logDate.toLocaleDateString("en-US", options);
      dayOfWeekData[dayOfWeek] += item[1];
    });

    // 결과 출력
    var resultArray = [];
    for (var day in dayOfWeekData) {
      resultArray.push([day, dayOfWeekData[day]]);
    }
    return resultArray.map(function (item) {
      return item[1];
    });
  }

  return (
    <div>
      {series === undefined ? (
        <img
          style={{ width: "50%", height: "50%" }}
          src={process.env.PUBLIC_URL + "Nodata.svg"}
          alt={""}
        />
      ) : (
        <Chart options={options} series={series} type="bar" />
      )}
    </div>
  );
}

export default SimpleStickChart;
