import React from "react";
import Contents from "../../Contents/Layout/Contents.jsx";
import Styles from "./Credit.module.css";
import ImageHeader from "../AccountSetting/contents/ImageHeader.tsx";
import { useTranslation } from "react-i18next";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import { useNavigate } from "react-router-dom";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader.jsx";
import CreatorProfile from "../../Contents/CreatorProfile/CreatorProfile.jsx";
import TextButton from "../../Common/button/TextButton.jsx";

const Credit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Contents
      component={
        <>
          <ComponentHeader title={"Credit"} />
          <CreatorProfile />
          <div className={Styles.rowcenter}>
            <ImageHeader title={t("Credit")} src="WorldManager.ico" />
            <div className={Styles.body}>
              <li className={Styles.row}>
                {t("ApexChart : © ")}
                <TextButton
                  text={t("ApexCharts")}
                  onClick={() => {
                    window.open("https://apexcharts.com/");
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("en Font : Gruppo ©  ")}
                <TextButton
                  text={t("Google")}
                  onClick={() => {
                    window.open(
                      "https://fonts.google.com/specimen/Gruppo?query=Gruppo+"
                    );
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("jp Font : LINESeedJP ©  ")}
                <TextButton
                  text={t("LINESeed")}
                  onClick={() => {
                    window.open("https://seed.line.me/index_jp.html");
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("kr Font : NanumSquareAc ©  ")}
                <TextButton
                  text={t("NAVER Font")}
                  onClick={() => {
                    window.open(
                      "https://campaign.naver.com/nanumsquare_neo/#nanumsquare_series"
                    );
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("Patreon Icon : © ")}
                <TextButton
                  text={t("Side_Project")}
                  onClick={() => {
                    window.open("https://www.iconfinder.com/Side_Project");
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("Github Icon : ©")}
                <TextButton
                  text={t("ibrandify")}
                  onClick={() => {
                    window.open(" https://www.iconfinder.com/ibrandify");
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("X Icon : ©")}
                <TextButton
                  text={t("x")}
                  onClick={() => {
                    window.open(
                      "https://about.x.com/ja/who-we-are/brand-toolkit"
                    );
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t("Icon : ©")}
                <TextButton
                  text={t("Google Icon")}
                  onClick={() => {
                    window.open("https://fonts.google.com/icons");
                  }}
                />
              </li>
              <li className={Styles.row}>
                {t(" © 2024 Team ALice All rights reserved")}
              </li>
            </div>
            <ImageHeader title={t("Special Thanks")} src="WorldManager.ico" />

            <div className={Styles.body}>
              <div style={{ fontWeight: "bold" }}>{t("Design Advice")}</div>
            </div>
            <img
              src={process.env.PUBLIC_URL + "aipo_icon.jpg"}
              alt=""
              style={{
                width: "40px",
                borderRadius: "50%",
                border: "1px solid #fff",
                boxShadow: "0px 0px 10px #fff",
              }}
            ></img>
            <div className={Styles.body}>
              <div
                style={{ fontWeight: "bold", textShadow: "0px 0px 10px #fff" }}
              >
                Aipo
              </div>
            </div>
            <ImageHeader title={t("Supporter")} src="WorldManager.ico" />
            <br />
            <div className={Styles.body}>
              <div
                style={{ fontWeight: "bold", textShadow: "0px 0px 10px #fff" }}
              >
                Team ALice, Namu, Developer Lee
              </div>
            </div>
            <br />
            <br />
            <Neonbutton text="Back" onClick={() => navigate(-1)} />
            <br></br>
            <br></br>
          </div>
        </>
      }
    />
  );
};

export default Credit;
