import React, { useEffect, useState } from "react";
import CustomTable from "../../Contents/Table/CustomTable.jsx";
import Status from "../../Contents/Status/Status.jsx";
import Popup from "../../Contents/PopUp/Popup.tsx";
import InputPopup from "../../Contents/PopUp/InputPopup.jsx";
import {
  callworldList,
  WorldDelete,
  CreateNewWorld,
  getUsage,
} from "./WorldCreateAxios.js";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import { useTranslation } from "react-i18next";
import { 알림내용변경, 알림변경 } from "../../redux/store/store.js";
import { useDispatch } from "react-redux";
const WorldCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };
  useEffect(() => {
    refreshList();
  }, []);
  async function refreshList() {
    callworldList().then((response) => {
      handleShowToast(response.header.status, response.header.code);
      if (response.header.code === "WORLD_READ_SUCCESS") {
        setworldlist(response.data.content);
      }
    });
    callUsage();
  }
  async function callUsage() {
    getUsage(localStorage.getItem("user")).then((response) => {
      setUsage([response.data.totalUsage, response.data.usage]);
    });
  }
  let [popup, setPopup] = useState(false);
  let [InputPopupState, setInputPopupState] = useState(false);
  let [worldlist, setworldlist] = useState([]);
  let [Usage, setUsage] = useState([10, 0]);

  let [alertdata, setAlertdata] = useState({
    title: t("World Delete"),
    text: t("are you sure to delete?"),
    btnText: t("delete"),
    target: "",
    targetIndex: 0,
    btnAction: deleteEvent,
  });

  let exitEvent = () => {
    setPopup(false);
  };
  let targetIndex = "worldId";
  let targetname = "worldName";
  let columnData = ["idx", "worldName", "worldCode"];
  let title = ["Total", "Usage"];

  const popupEvent = (target, targetIndex) => {
    // let title =
    setAlertdata({
      ...alertdata,
      target: target,
      targetIndex: targetIndex,
      title: t("World Delete"),
      btnText: t("delete"),
      btnAction: deleteEvent,
    });
    setPopup(true);
  };
  function deleteEvent(Index) {
    WorldDelete(Index).then((result) => {
      handleShowToast(result.data.header.status, result.data.header.code);
      refreshList().then(() => {
        setPopup(false);
      });
    });
  }

  const BtnEvent = () => {
    setAlertdata({
      ...alertdata,
      title: t("Create World"),
      btnText: t("Create"),
      btnAction: addNewWorld,
    });
    setInputPopupState(true);
  };

  function addNewWorld(worldname) {
    CreateNewWorld(worldname).then((result) => {
      handleShowToast(200, "WORLD_CREATE_SUCCESS");
      console.log("refreshList");
      refreshList();
      setInputPopupState(false);
    });
  }
  return (
    <>
      <Status title={title} data={Usage} />
      <CustomTable
        title={t("CreateWorld")}
        data={worldlist}
        columnData={columnData}
        popupEvent={popupEvent}
        targetname={targetname}
        targetIndex={targetIndex}
        Components={
          <>
            <br />
            <Neonbutton
              text={t("Create World")}
              style={{
                margin: "0 auto",
              }}
              onClick={BtnEvent}
              isActive={Usage[0] > Usage[1] ? true : false}
            />
            <br />
          </>
        }
      />
      {popup ? <Popup alertdata={alertdata} exitEvent={exitEvent} /> : ""}
      {InputPopupState && (
        <InputPopup alertdata={alertdata} exitEvent={setInputPopupState} />
      )}
    </>
  );
};

export default WorldCreate;
