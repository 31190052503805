import React from "react";
import ContentsHeader from "../Layout/ContentsHeader.jsx";
import Contentstitle from "../Layout/Contentstitle.jsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Styles from "./Popup.module.css";
import PopupHeader from "../../Layout/popupHeader/popupHeader.jsx";

interface AlertData {
  title: string;
  text: string;
  target: string;
  btnText: string;
  btnAction: (targetIndex: number) => void;
  targetIndex: number;
}

interface PopupProps {
  alertdata: AlertData;
  exitEvent: (value: boolean) => void;
}

const Popup: React.FC<PopupProps> = (props) => {
  function exitTrigger() {
    props.exitEvent(false);
  }

  function stopPropagation(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
  }

  function btnclickEvent() {
    props.alertdata.btnAction(props.alertdata.targetIndex);
  }

  return (
    <div className={Styles.popupBackground} onClick={exitTrigger}>
      <div className={Styles.popup} onClick={stopPropagation}>
        <PopupHeader exitTrigger={exitTrigger} />
        <ContentsHeader
          component={<Contentstitle title={props.alertdata.title} />}
        />
        <p>{props.alertdata.text}</p>
        <h3>{props.alertdata.target}</h3>
        <br />
        <Neonbutton text={props.alertdata.btnText} onClick={btnclickEvent} />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Popup;
