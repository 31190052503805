import axios from "axios";

type JoinData = {
  nickName: string;
  email: string;
  password: string;
};

const join = async (data: JoinData) => {
  try {
    const response = await axios.post("join", data);
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response.data.header.code === "DUPLICATE_USER") {
      return error.response.data;
    }
  }
};

export default join;
