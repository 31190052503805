import React from "react";
import Styles from "./Toast.module.css";
import { useTranslation } from "react-i18next";

const Toast = ({ props }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.toast}>
      <img
        src={
          props.code === 200 || props.code === 201
            ? process.env.PUBLIC_URL + "Agree.svg"
            : process.env.PUBLIC_URL + "Block.svg"
        }
        alt=""
      ></img>
      {t(props.text)}
    </div>
  );
};

export default Toast;
