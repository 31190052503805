import client from "../../js/axiosConfig";

const fetchData = (url) => {
  return client.get(url).then((response) => response.data);
};

function getAnailerTrafficWorldVisitorRatio(values) {
  let url = "/analizer/traffic/world/visitorratio";
  if (values) {
    if (values.start) url += `?start=${values.start}`;
    // if (values.end) url += `&end=${values.end}`;
  }
  return fetchData(url).then((data) => {
    return data.data;
  });
}

const callAllworldTraffic = (values) => {
  let url = "/analizer/traffic/world/growthchart/count";
  if (values) {
    if (values.start) url += `?start=${values.start}`;
    if (values.end) url += `&end=${values.end}`;
  }
  return fetchData(url).then((data) => {
    return data.data;
  });
};

const callAllworldTrafficpercent = (values) => {
  let url = "/analizer/traffic/world/growthchart/percent";
  if (values) {
    if (values.start) url += `?start=${values.start}`;
    if (values.end) url += `&end=${values.end}`;
  }
  return fetchData(url).then((data) => {
    return data.data;
  });
};

const callAllworldTrafficCardBytime = (values) => {
  let url = "/analizer/traffic/world/card";
  if (values) {
    if (values.start) url += `?start=${values.start}`;
    if (values.end) url += `&end=${values.end}`;
  }
  return fetchData(url).then((data) => {
    return data.data;
  });
};

export {
  callAllworldTraffic,
  callAllworldTrafficCardBytime,
  getAnailerTrafficWorldVisitorRatio,
  callAllworldTrafficpercent,
};
