import { useTranslation } from "react-i18next";
import Styles from "./LineInput.module.css";
import React from "react";

type LineInputProps = {
  label?: string;
  id?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  style?: React.CSSProperties;
  name?: string;
};
function LineInput({
  label,
  id,
  value,
  onChange,
  placeholder,
  type,
  style,
  name,
}: LineInputProps) {
  const { i18n } = useTranslation();
  return (
    <>
      <div className={Styles.lineInput} style={style}>
        <label htmlFor={id} className={i18n.language}>
          {label}
        </label>
        <input
          id={id}
          name={name}
          className={i18n.language}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          type={type}
          style={style}
        ></input>
      </div>
    </>
  );
}

export default LineInput;
