import Chart from "react-apexcharts";

import React, { useEffect, useState } from "react";

const SimpleGrowthChart = (props) => {
  let [series, series변경] = useState([]);
  let selected = 0;

  useEffect(() => {
    let temp = [];
    if (props.data !== undefined) {
      //props.data = {time: [], value: []}
      //temp.push({data: [time[i],value[i]]})
      for (let i = 0; i < props.data.time.length; i++) {
        temp.push([props.data.time[i], props.data.value[i]]);
      }
      let result = [{ name: "Visitor", data: temp }];
      series변경(result);
    }
  }, [props.data]);
  let options = {
    chart: {
      type: "bar",
      dropShadow: {
        enabled: true,
        top: 10,
        left: 5,
        blur: 5,
        opacity: 0.2,
      },
      height: 230,
      background: "transparent",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100,
              to: -46,
              color: "#F15B46",
            },
            {
              from: -45,
              to: 0,
              color: "#FEB019",
            },
          ],
        },
        columnWidth: "100%",
      },
    },
    dataLabels: {
      enabled: false,
    },

    theme: {
      mode: "dark",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 4,
      axisBorder: {
        show: false,
      },
      labels: {
        rotate: -90,
        style: {
          colors: "#8e8da4",
          fontSize: "14px",
          fontWeight: 100,
        },
        offsetY: -5,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },

    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      show: false,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#8e8da4",
        },
        formatter: function (y) {
          return y.toFixed(0) + "%";
        },
      },
    },

    grid: {
      yaxis: {
        lines: {
          offsetX: -30,
        },
      },
      padding: {
        left: 20,
      },
    },
  };

  return (
    <div style={{ textAlign: "center", width: "95%" }}>
      {props.data === undefined ? (
        <img
          style={{ width: "50%", height: "50%" }}
          src={process.env.PUBLIC_URL + "Nodata.svg"}
          alt={""}
        />
      ) : (
        <Chart options={options} series={series} type="bar" height={230} />
      )}
    </div>
  );
};

export default SimpleGrowthChart;
