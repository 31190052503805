import React from "react";
import Styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={Styles.footer}>
      <div className={Styles.column}>
        <img
          src={process.env.PUBLIC_URL + "logo.png"}
          alt=""
          style={{ width: "20px", marginRight: "10px" }}
        ></img>
        <div className={Styles.body}>
          <div
            style={{
              textShadow: "0 0 4px #FFFFFF, 0 0 7px #1205B1, 0 0 9px #5CD2FC",
            }}
          >
            Alice Manager
          </div>
        </div>
      </div>
      <div className={Styles.column}>
        <div
          className={Styles.link}
          onClick={() => {
            navigate("/Privacy");
          }}
        >
          {t("Privacy Policy")}
        </div>
        <div className={Styles.line}></div>
        <div
          className={Styles.link}
          onClick={() => {
            navigate("/Rules");
          }}
        >
          {t("Terms for use")}
        </div>
        <div className={Styles.line}></div>
        <div
          className={Styles.link}
          onClick={() => {
            navigate("/Help");
          }}
        >
          {t("Help")}
        </div>
      </div>
      <div className={Styles.body}>
        <div>
          {t("Production By")}{" "}
          <strong className={Styles.highLight}>Team Alice</strong>
        </div>
      </div>
      <div className={Styles.body}>
        <div>
          {t("Create By")}{" "}
          <strong className={Styles.highLight}>yosuzume</strong>
        </div>
      </div>
      <div className={Styles.license}>
        <div>© 2024 Team ALice All rights reserved</div>
      </div>
    </div>
  );
};

export default Footer;
