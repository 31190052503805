import React from "react";
import Styles from "./Layer.module.css";

const Layer = (props) => {
  // Component logic goes here

  return <div className={Styles.Layer}>{props.component}</div>;
};

export default Layer;
