import client from "../../js/axiosConfig";

const SendToMessage = async (data) => {
  try {
    return await client.post("/sendEmail", data);
  } catch (error) {
    return error.response;
  }
};
export { SendToMessage };
