import client from "../../js/axiosConfig";

const logoutAxios = async () => {
  try {
    await client.post("/logout").then((response) => {
      if (response.data.header.code === "USER_LOGOUT_SUCCESS") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("nickname");
        localStorage.removeItem("email");
        localStorage.removeItem("user");
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export { logoutAxios };
