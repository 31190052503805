import client from "../../js/axiosConfig";
function callLogList(page, size, worldName) {
  let pagesize = size;
  if (size === 0) pagesize = 10;
  if (size === 1) pagesize = 10;
  if (size === 2) pagesize = 30;
  if (size === 3) pagesize = 50;
  if (size === 4) pagesize = 100;
  let url = `/log/list/${page + 1}?size=` + pagesize;
  if (worldName !== "None") url += `&WorldName=` + worldName;
  return client.get(url).then((response) => {
    return response.data;
  });
}
const callworldList = () => fetchData("/worldlist");

function deleteLogList(Index) {
  return client.delete(`/log?id=${Index}`);
}

const fetchData = (url) => client.get(url).then((response) => response.data);

export { callLogList, deleteLogList, callworldList };
