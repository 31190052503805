import Styles from "./Subcontents.module.css";
import React from "react";
function Subcontents(props) {
  return (
    <>
      <div className={Styles.subcontents} style={props.style}>
        {props.component}
      </div>
    </>
  );
}

export default Subcontents;
