import React, { ReactNode, MouseEvent } from "react";
import Styles from "./InputPopup.module.css";
import { useTranslation } from "react-i18next";
import PopupHeader from "../../../Layout/popupHeader/popupHeader";

interface InputPopupProps {
  exitEvent: (exit: boolean) => void;
  components: ReactNode;
}

const InputPopup: React.FC<InputPopupProps> = ({ exitEvent, components }) => {
  const { t } = useTranslation();

  function exitTrigger() {
    exitEvent(false);
  }

  function stopPropagation(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  return (
    <div className={Styles.inputPopupBackground} onClick={exitTrigger}>
      <div className={Styles.inputPopup} onClick={stopPropagation}>
        <PopupHeader exitTrigger={exitTrigger} />

        {components}
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default InputPopup;
