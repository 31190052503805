import axios from "axios";

const client = axios.create({
  baseURL: "/api",
});

client.interceptors.request.use(function (config) {
  const user = localStorage.getItem("accessToken");
  if (user) {
    config.headers["Authorization"] = `Bearer ${user}`;
  }
  return config;
});

let isRefreshing = false; // 추가된 변수: reissue 요청이 진행 중인지 여부를 추적

client.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      error.response.data.header &&
      (error.response.data.header.code === "INVALID_TOKEN" ||
        error.response.data.header.code === "TOKEN_EXPIRED")
    ) {
      const originalRequest = error.config;
      if (!isRefreshing) {
        // reissue 요청이 이미 진행 중인 경우 추가 요청을 방지
        isRefreshing = true; // reissue 요청이 시작됨을 표시
        try {
          const data = await client.post("/reissue");
          if (data.data.header.code !== "USER_REISSUE_SUCCESS")
            throw new Error("USER_REISSUE_FAIL");
          if (data) {
            const accessToken = data.data.data.token.accessToken;
            localStorage.removeItem("accessToken");
            localStorage.setItem("accessToken", accessToken);
            originalRequest.headers["accessToken"] = accessToken;
            return await client.request(originalRequest);
          }
        } catch (error) {
          localStorage.clear();
        } finally {
          isRefreshing = false; // reissue 요청이 완료되었음을 표시
        }
      }
      return Promise.reject(error);
    } else {
      return error.response;
    }
  }
);

export default client;
