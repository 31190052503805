import { useTranslation } from "react-i18next";
import Styles from "./Text.module.css";
import React from "react";
function Text({ text, style, Styleclass }) {
  const { i18n } = useTranslation();
  const additionalClass = i18n.language;
  const combinedClassName = `${Styles.text} ${additionalClass} ${Styleclass}`;
  return (
    <p className={combinedClassName} style={style}>
      {text}
    </p>
  );
}

export default Text;
