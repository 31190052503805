import React, { useState, useEffect } from "react";
import Contents from "../../Contents/Layout/Contents.jsx";
import ContentsHeader from "../../Contents/Layout/ContentsHeader.jsx";
import Contentstitle from "../../Contents/Layout/Contentstitle.jsx";
import Subcontents from "../../Contents/Layout/Subcontents.jsx";
import LineInput from "../../Common/Input/LineInput.tsx";
import TextButton from "../../Common/button/TextButton.jsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Text from "../../Common/Text/Text.jsx";
import Styles from "./JoinCheck.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkCertification, checkEmail } from "./JoinCheckAxios.ts";
import { useDispatch } from "react-redux";
import {
  알림내용변경,
  알림변경,
  로그인후메뉴,
} from "../../redux/store/store.js";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader.jsx";

const JoinCheck = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSend, setIsSend] = useState(false);
  const [timer, setTimer] = useState(180); // 3 minutes in seconds
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [certificationCode, setCertificationCode] = useState("");

  useEffect(() => {
    let countdownInterval;

    if (isSend) {
      setIsButtonEnabled(false);
      countdownInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 0) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      const buttonEnableTimeout = setTimeout(() => {
        setIsButtonEnabled(true);
      }, 30000); // 30 seconds

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(buttonEnableTimeout);
      };
    }
  }, [isSend]);

  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };

  const goToLogin = () => {
    navigate("/Login");
  };

  const sendCertificationCode = () => {
    checkEmail().then((response) => {
      handleShowToast(response.header.status, response.header.code);
      setIsSend(true);
      setTimer(180); // Reset the timer to 3 minutes
    });
  };

  const formchange = (e) => {
    setCertificationCode(e.target.value);
  };

  const CheckCertification = () => {
    checkCertification(certificationCode).then((response) => {
      if (
        response.header.code &&
        response.header.code === "EMAIL_AUTH_CODE_CHECK_SUCCESS"
      ) {
        handleShowToast(response.header.status, response.header.code);
        //인증 완료 시 게스트 모드 종료 및 원래 로그인 모드로 변경
        localStorage.removeItem("authority");
        dispatch(로그인후메뉴());
        navigate("/DashBoard");

        // handle successful certification
      } else {
        alert("인증에 실패하였습니다.");
        console.log(response);
      }
    });
  };

  return (
    <>
      <Contents
        component={
          <>
            <ComponentHeader title={t("Email Authentication")} />
            <ContentsHeader
              component={<Contentstitle title={t("Email Authentication")} />}
            />
            <Subcontents
              component={
                <>
                  <br />
                  <p className={Styles.text}>
                    {t("We need email authentication to use our application.")}
                  </p>
                  <p className={Styles.text}>
                    {t(
                      "Click the SendCode button to get issued to the e-mail where you entered the code, check the code, and enter it"
                    )}
                  </p>
                  <br />
                  <br />
                  <Neonbutton
                    isActive={isButtonEnabled}
                    text={t("SendCode")}
                    onClick={sendCertificationCode}
                  />
                  <br />
                  {isSend && (
                    <Text
                      Styleclass={Styles.timer}
                      text={`Time remaining: ${Math.floor(timer / 60)}:${String(
                        timer % 60
                      ).padStart(2, "0")}`}
                    />
                  )}
                  <LineInput
                    style={{ textAlign: "center", alignItems: "center" }}
                    label={t("Certification Code")}
                    placeholder={t("Enter Your Certification Code")}
                    type={"text"}
                    onChange={formchange}
                  />
                </>
              }
            />
            <br />
            <Subcontents
              component={
                <>
                  <Neonbutton
                    text={t("Confirm")}
                    onClick={CheckCertification}
                  />
                  <br />
                  <TextButton text={t("Login")} onClick={goToLogin} />
                  <br />
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default JoinCheck;
