import React from "react";
import Styles from "./ComponentHeader.module.css";
import { useTranslation } from "react-i18next";

function ComponentHeader(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className={Styles.root}>
      <div className={Styles.body}>
        <img
          src={process.env.PUBLIC_URL + "WorldManager.ico"}
          alt="Logo"
          className={Styles.logo + " " + i18n.language + "headerimg"}
        />
        <div className={i18n.language + "header"} style={{ marginTop: "2px" }}>
          {t(props.title)}
          {props.Subtitle}
        </div>
      </div>
    </div>
  );
}

export default ComponentHeader;
