import { React, useState } from "react";
import ContentsHeader from "../Layout/ContentsHeader.jsx";
import Contentstitle from "../Layout/Contentstitle.jsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Styles from "./InputPopup.module.css";
import Text from "../../Common/Text/Text.jsx";
import LineInput from "../../Common/Input/LineInput.tsx";
import { useTranslation } from "react-i18next";
import PopupHeader from "../../Layout/popupHeader/popupHeader.jsx";

const InputPopup = (props) => {
  const { t } = useTranslation();
  let [Worldname, SetWorldName] = useState("");

  function exitTrigger() {
    props.exitEvent(false);
  }

  function stopPropagation(e) {
    e.stopPropagation();
  }

  function btnclickEvent() {
    props.alertdata.btnAction(Worldname);
  }

  function test(test) {
    SetWorldName(test);
  }

  return (
    <div className={Styles.inputPopupBackground} onClick={exitTrigger}>
      <div className={Styles.inputPopup} onClick={stopPropagation}>
        <PopupHeader exitTrigger={exitTrigger} />
        <ContentsHeader
          component={<Contentstitle title={props.alertdata.title} />}
        />
        <Text text={t("Enter Your WorldName")} />
        <br />
        <LineInput
          style={{ textAlign: "center", alignItems: "center" }}
          label={t("WorldName")}
          placeholder={t("Enter Your WorldName")}
          type={"text"}
          value={Worldname}
          onChange={(e) => test(e.target.value)}
        />
        <br />
        <Neonbutton text={props.alertdata.btnText} onClick={btnclickEvent} />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default InputPopup;
