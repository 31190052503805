import { useTranslation } from "react-i18next";
import Styles from "./Neonbutton.module.css";
import React from "react";

type NeonbuttonProps = {
  text: string;
  onClick: () => void;
  style?: React.CSSProperties;
  isActive?: boolean;
};

function Neonbutton({
  text,
  onClick,
  style,
  isActive = true,
}: NeonbuttonProps) {
  const { i18n, t } = useTranslation();
  return (
    <>
      <button
        style={style}
        className={`${Styles.neonbutton} ${i18n.language} ${
          !isActive ? Styles.disabled : ""
        }`}
        onClick={onClick}
        disabled={!isActive}
      >
        {t(text)}
      </button>
    </>
  );
}

export default Neonbutton;
