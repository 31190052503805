import client from "../../js/axiosConfig";

const sendEmail = (email) => {
  let url = "/SendPasswordCertification";
  const data = {
    email: email,
  };
  return client.post(url, data).then((response) => response.data);
};

const CheckCertificationCode = (email, code) => {
  let url = "/CheckPasswordCertification";
  const data = {
    email: email,
    certificationCode: code,
  };
  return client.post(url, data).then((response) => response.data);
};

const PasswordChange = (password) => {
  let url = "/PasswordChange" + localStorage.getItem("user");
  const data = {
    password: password,
    semiAccessCode: localStorage.getItem("semiAccessCode"),
  };
  return client.patch(url, data).then((response) => response.data);
};

export { sendEmail, CheckCertificationCode, PasswordChange };
