import React from "react";
import Styles from "./ImageButton.module.css";

type buttonElement = {
  text: string;
  src: string;
  style?: object;
  onClick?: () => void;
};

const ImageButton = ({ text, src, style, onClick }: buttonElement) => (
  <div className={Styles.block} style={style} onClick={onClick}>
    <img
      src={process.env.PUBLIC_URL + src}
      alt=""
      style={{ width: "13px", paddingRight: "5px" }}
    />
    {text}
  </div>
);

export default ImageButton;
