import client from "../../js/axiosConfig";

const processDTO = (DTO) => {
  //DTO 오브젝트가 비어있을 경우 0 리턴
  if (!DTO.totalDTO) return [];
  let user = 0,
    total = 0,
    visitor = 0,
    saveCode = 0,
    data = [];
  DTO.gameUserDTO.forEach((item) => (user += item.logCount));
  DTO.totalDTO.forEach((item) => (total += item.logCount));
  DTO.savecodeDTO.forEach((item) => (saveCode += item.logCount));
  DTO.visitorDTO.forEach((item) => {
    visitor += item.logCount;
    data.push([
      new Date(item.logDate.replace(" ", "T") + "Z").getTime(),
      item.logCount,
    ]);
  });

  return {
    User: user,
    Total: total,
    Visitor: visitor,
    SaveCode: saveCode,
    data: data,
  };
};

const fetchData = (url) => client.get(url).then((response) => response.data);

const callworldList = () => fetchData("/worldlist");

const callworldTrafficByWorldidx = (index) =>
  fetchData(`/analizer/traffic/world/${index}`).then((data) => ({
    title: data.data.title,
    ...processDTO(data.data),
  }));

const callAllworldTraffic = () =>
  fetchData("/analizer/traffic/world").then((data) => {
    if (!Array.isArray(data.data)) data.data = [data.data];
    return data.data.map((element) => ({
      title: element.title,
      ...processDTO(element),
    }));
  });

const callworldTrafficByWorldidxBytime = (index, time) =>
  fetchData(
    `/analizer/traffic/world/${index}?start=${callstartDate(time)}`
  ).then((data) => ({
    title: data.data.title,
    ...processDTO(data.data),
  }));

const callAllworldTrafficBytime = (time) =>
  fetchData(`/analizer/traffic/world?start=${callstartDate(time)}`).then(
    (data) => {
      if (!Array.isArray(data.data)) data.data = [data.data];
      return data.data.map((element) => ({
        title: element.title,
        ...processDTO(element),
      }));
    }
  );

const callstartDate = (time) => {
  let date = new Date();
  date.setDate(date.getDate() - time);
  return date.toISOString();
};

export {
  callworldList,
  callworldTrafficByWorldidx,
  callAllworldTraffic,
  callworldTrafficByWorldidxBytime,
  callAllworldTrafficBytime,
};
