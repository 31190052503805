import React, { useState } from "react";
import Contents from "../../Contents/Layout/Contents.jsx";
import ContentsHeader from "../../Contents/Layout/ContentsHeader.jsx";
import Contentstitle from "../../Contents/Layout/Contentstitle.jsx";
import Subcontents from "../../Contents/Layout/Subcontents.jsx";
import LineInput from "../../Common/Input/LineInput.tsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Text from "../../Common/Text/Text.jsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { 알림변경, 알림내용변경 } from "../../redux/store/store.js";
import { PasswordChange } from "./PasswordFindAxios.js";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader.jsx";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Component logic goes here
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/Login");
  };
  const [form, setForm] = useState({ password: "", checkPassword: "" });
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };
  const passwordchange = () => {
    //정규식 검사
    const passwordReg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{10,}$/;
    if (!passwordReg.test(form.password)) {
      handleShowToast(
        400,
        "Password must be at least 12 characters long and must contain a special character"
      );
      return;
    }
    if (form.password !== form.checkPassword) {
      handleShowToast(400, "Passwords do not match");
      return;
    }
    PasswordChange(form.password).then((response) => {
      if (response.header.status === 200) {
        handleShowToast(response.header.status, response.header.code);
        goToLogin();
      } else {
        handleShowToast(response.header.status, response.header.code);
      }
    });
  };

  return (
    <>
      <Contents
        component={
          <>
            <ComponentHeader title={t("Change Password")} />
            <ContentsHeader
              component={<Contentstitle title={t("Change Password")} />}
            />
            <Subcontents
              component={
                <>
                  <br />
                  <Text text={t("Please Enter Your New Password")} />
                  <br />
                  <LineInput
                    label={t("Password")}
                    placeholder={t("Enter Your Password")}
                    type={"Password"}
                    name={"password"}
                    onChange={handleChange}
                  />
                  <LineInput
                    label={t("Check Password")}
                    placeholder={t("Enter Your Password Again")}
                    type={"Password"}
                    name={"checkPassword"}
                    onChange={handleChange}
                  />
                </>
              }
            />
            <br />
            <Subcontents
              component={
                <>
                  <Neonbutton text={t("Submit")} onClick={passwordchange} />
                  <br />
                  <br />
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default ChangePassword;
