import SimpleLineChart from "../../../Chart/SimpleLineChart";
import Contentsbody from "../../../Contents/Layout/Contentsbody";
import Styles from "./DashBoard.module.css";
import React, { useState } from "react";
import SimpleStickChart from "../../../Chart/SimpleStickChart";
import {
  callworldTrafficByWorldidx,
  callworldTrafficByWorldidxBytime,
} from "../WorldReportAxios.js";
import TableHeader from "../../DashBoard/contents/TableHeader";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import ComponentHeader from "../../../Layout/ComponentHeader/ComponentHeader";

function DashBoard(props) {
  const { t } = useTranslation();
  let [data, data변경] = useState([]);
  const asyncTrafficData = async () => {
    let date = props.date;
    let worldId = props.worldId;
    if (date === 1) date = 1;
    else if (date === 2) date = 7;
    else if (date === 3) date = 30;
    else if (date === 4) date = 365;

    console.log("CallData");
    if (date === 0) {
      const response = await callworldTrafficByWorldidx(worldId);
      data변경(response);
    } else {
      const response = await callworldTrafficByWorldidxBytime(worldId, date);
      data변경(response);
    }
  };
  const { isLoading } = useQuery(
    [props.worldId + "", props.date],
    asyncTrafficData,
    { cacheTime: 300000, refetchOnWindowFocus: false }
  );

  return (
    <>
      <div className={Styles.dashBoard}>
        <ComponentHeader
          title={"World Report"}
          Subtitle={" / " + (isLoading ? "Loading..." : data.title)}
        />
        <Contentsbody
          style={{ width: "100%" }}
          component={<TableHeader date={props.date} />}
        />
        <div
          className={Styles.header}
          style={{
            borderTop: "0px",
            borderBottom: "0.1px solid rgba(0, 0, 0, 0.6)",
          }}
        >
          {t("Visitor Graph")}
        </div>
        <br></br>
        {!data.data ? (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: "40%" }}
              src={process.env.PUBLIC_URL + "Nodata.svg"}
              alt={""}
            />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        ) : (
          <Contentsbody
            component={
              <>
                <div style={{ width: "100%" }}>
                  <SimpleLineChart data={data.data} />
                </div>
              </>
            }
          />
        )}
        <div
          className={Styles.header}
          style={{
            borderTop: "0px",
            borderBottom: "0.1px solid rgba(0, 0, 0, 0.6)",
          }}
        >
          {t("Weekly Visitor")}
        </div>
        <br></br>
        {!data.data ? (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: "40%" }}
              src={process.env.PUBLIC_URL + "Nodata.svg"}
              alt={""}
            />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        ) : (
          <Contentsbody
            component={
              <>
                <div style={{ width: "100%" }}>
                  <SimpleStickChart data={data.data} />
                </div>
              </>
            }
          />
        )}
        <div
          className={Styles.header}
          style={{
            borderTop: "0px",
            borderBottom: "0.1px solid rgba(0, 0, 0, 0.6)",
          }}
        >
          {t("Total Count")}
        </div>
        <br></br>
        <Contentsbody
          style={{ justifyContent: "space-around" }}
          component={
            <>
              {!data.data ? (
                <div>
                  <img
                    style={{ width: "40%" }}
                    src={process.env.PUBLIC_URL + "Nodata.svg"}
                    alt={""}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              ) : (
                <>
                  <div>
                    <h1 style={{ width: "100%" }}>{t("Visitor")} </h1>
                    <p>{data.Visitor}</p>
                  </div>
                  <div>
                    <h1 style={{ width: "100%" }}>{t("Total")} </h1>
                    <p>{data.Total}</p>
                  </div>
                  <div>
                    <h1 style={{ width: "100%" }}>{t("User")} </h1>
                    <p>{data.User}</p>
                  </div>
                  <div>
                    <h1 style={{ width: "100%" }}>{t("SaveCode")} </h1>
                    <p>{data.SaveCode}</p>
                  </div>
                </>
              )}
            </>
          }
        />
      </div>
    </>
  );
}

export default DashBoard;
