import Styles from "./Submenu.module.css";
import React from "react";
import { useState } from "react";

function Submenu(props) {
  return (
    <>
      <div>
        <div className={Styles.subMenu}>
          {Object.keys(props.menu).map((item, index) => {
            return (
              <div key={index}>
                <Menu
                  name={
                    props.SelectedMenu[index] === 0
                      ? item
                      : props.menu[item][props.SelectedMenu[index]]
                  }
                  {...{
                    contents: props.menu[item],
                  }}
                  index={index}
                  SelectedMenuChange={props.SelectedMenuChange}
                />
                <div className={Styles.line}></div>
              </div>
            );
          })}
        </div>
        <h1>{props.titile}</h1>
      </div>
    </>
  );
}

function Menu(props) {
  let [isClicked, isClickedChange] = useState(false);
  let contents = props.contents;
  let index = props.index;
  function clickEvent(number) {
    props.SelectedMenuChange((prevState) => {
      let newState = prevState.slice();
      newState[index] = number;
      return newState;
    });
    isClickedChange(!isClicked);
  }
  function MenuClickEvent() {
    isClickedChange(!isClicked);
  }

  return (
    <div className={Styles.dropdown}>
      <div className={Styles.menu} onClick={MenuClickEvent}>
        {props.name}
      </div>
      {isClicked && (
        <>
          <DropdownContents contents={contents} onClick={clickEvent} />
          <div className={Styles.backdrop}></div>
        </>
      )}
    </div>
  );
}

function DropdownContents(props) {
  function clickEvent(index) {
    if (index === undefined) return;
    props.onClick(index);
  }

  return (
    <div className={Styles.dropdownContents}>
      {props.contents.map((item, index) => {
        return (
          <div key={index} onClick={() => clickEvent(index)}>
            {item}
          </div>
        );
      })}
    </div>
  );
}

export default Submenu;
