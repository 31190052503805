import React, { useEffect } from "react";
import CustomTable from "../../Contents/Table/CustomTable.jsx";
import Submenu from "../../Contents/Layout/Submenu.jsx";
import { useState } from "react";
import Popup from "../../Contents/PopUp/Popup.tsx";
import { callGameUserList, callGameUserDelete } from "./GameUserListAxios.js";
import { useDispatch, useSelector } from "react-redux";
import { 알림내용변경, 알림변경 } from "../../redux/store/store.js";

const GameUserList = () => {
  const dispatch = useDispatch();
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };
  let [GameUserList, setGameUserList] = useState([]);
  let [popup, setPopup] = useState(false);
  let 월드목록 = useSelector((state) => {
    return state.월드목록;
  });
  const [선택된메뉴, 선택된메뉴변경] = useState([0, 0]);
  let [menu, menuSet] = useState({
    World: ["None"],
    Count: ["None", "10", "30", "50", "100"],
  });
  let [alertdata, setAlertdata] = useState({
    title: "GameUser Delete",
    text: "are you sure to delete?",
    btnText: "delete",
    target: "",
    btnAction: offPopUp,
  });
  let columnData = ["worldName", "userCode", "createAt"];
  let [pageable, pageable변경] = useState();
  let [page, page변경] = useState(0);
  let targetIndex = "gameUserIdx";
  let targetname = "userCode";
  useEffect(() => {
    CallworldList();
  }, [월드목록]);
  useEffect(() => {
    refreshList();
  }, [page, 선택된메뉴]);
  async function refreshList() {
    callGameUserList(page, 선택된메뉴[1], menu.World[[선택된메뉴[0]]]).then(
      (response) => {
        handleShowToast(response.header.status, response.header.code);
        if (response.header.code === "GAMEUSER_READ_SUCCESS") {
          setGameUserList(DateFormat(response.data.content));
          pageable변경(response.data);
        }
      }
    );
  }

  function PageChange(page) {
    page변경(page);
  }
  function DateFormat(origindata) {
    origindata.forEach((element) => {
      var date;
      date = element.createAt.split("T")[0];
      date += " " + element.createAt.split("T")[1].split(".")[0];
      element.createAt = date;
    });
    return origindata;
  }

  let exitEvent = () => {
    setPopup(false);
  };
  let popupEvent = (target, targetIndex) => {
    // let title =
    setAlertdata({
      ...alertdata,
      target: target,
      targetIndex: targetIndex,
      title: "GameUser Delete",
      btnText: "delete",
      btnAction: deleteEvent,
    });
    setPopup(true);
  };
  function deleteEvent(index) {
    callGameUserDelete(index).then(() => {
      refreshList().then(() => {
        handleShowToast(200, "GAMEUSER_DELETE_SUCCESS");
        setPopup(false);
      });
    });
  }
  function offPopUp() {
    setPopup(false);
  }

  function CallworldList() {
    if (월드목록) {
      let worldName = 월드목록.map((item) => item.worldName);
      worldName.unshift("None"); //맨 앞열에 None 추가
      menuSet({ ...menu, World: worldName });
    }
  }
  return (
    <>
      <Submenu
        menu={menu}
        SelectedMenu={선택된메뉴}
        SelectedMenuChange={선택된메뉴변경}
      />
      <CustomTable
        title={"GameUserList"}
        data={GameUserList}
        columnData={columnData}
        popupEvent={popupEvent}
        pageable={pageable}
        pageChange={PageChange}
        targetIndex={targetIndex}
        targetname={targetname}
      />
      {popup && <Popup alertdata={alertdata} exitEvent={exitEvent} />}
    </>
  );
};

export default GameUserList;
