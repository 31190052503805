import { configureStore, createSlice } from "@reduxjs/toolkit";

let 월드목록 = createSlice({
  name: "월드목록",
  initialState: "", //default 값
  reducers: {
    월드목록변경(state, action) {
      //기존 state를 말합니다.
      return (state = action.payload); // 라는 형식으로도 사용할 수있고
      // 여러 함수를 만들고 싶으면
    },
  },
});
let 로그인여부 = createSlice({
  name: "로그인여부",
  initialState: false,
  reducers: {
    로그인여부변경(state, action) {
      return (state = action.payload);
    },
  },
});
let Hamberger = createSlice({
  name: "Hamberger",
  initialState: false,
  reducers: {
    HambegerOpen(state, action) {
      return (state = action.payload);
    },
    HambegerClose(state, action) {
      return (state = action.payload);
    },
  },
});

let 알림 = createSlice({
  name: "알림",
  initialState: "",
  reducers: {
    알림변경(state, action) {
      return (state = action.payload);
    },
  },
});

let 알림내용 = createSlice({
  name: "알림내용",
  initialState: "test",
  reducers: {
    알림내용변경(state, action) {
      return (state = action.payload);
    },
  },
});

let joinform = createSlice({
  name: "joinform",
  initialState: {
    nickName: "",
    email: "",
    password: "",
    passwordCheck: "",
    privarcy: false,
    rulse: false,
  },
  reducers: {
    joinform변경(state, action) {
      return (state = action.payload);
    },
    joinform초기화(state) {
      return (state = {
        nickName: "",
        email: "",
        password: "",
        passwordCheck: "",
        privarcy: false,
        rulse: false,
      });
    },
  },
});

let 메뉴 = createSlice({
  name: "메뉴",
  initialState: [],
  reducers: {
    메뉴변경(state, action) {
      return (state = action.payload);
    },
    로그인후메뉴(state) {
      return (state = [
        { title: "DashBoard", path: "/DashBoard" },
        { title: "World Report", path: "/WorldReport" },
        { title: "World Create", path: "/WorldCreate" },
        { title: "GameUser List", path: "/GameUserList" },
        { title: "SaveCode List", path: "/SavecodeList" },
        { title: "Log List", path: "/LogList" },
        { title: "Help", path: "/Help" },
        { title: "Contact Us", path: "/ContactUs" },
        { title: "Privacy Policy", path: "/Privacy" },
        { title: "Account Setting", path: "/Account" },
        { title: "Terms for use", path: "/Rules" },
        { title: "Credit", path: "/Credit" },
      ]);
    },
    로그인전메뉴(state) {
      return (state = [
        { title: "Login", path: "/Login" },
        { title: "Find Password", path: "/PasswordFind" },
        { title: "Join", path: "/Join" },
        { title: "Privacy Policy", path: "/Privacy" },
        { title: "Terms for use", path: "/Rules" },
        { title: "Help", path: "/Help" },
        { title: "Credit", path: "/Credit" },
      ]);
    },
    게스트메뉴(state) {
      return (state = [
        { title: "Help", path: "/Help" },
        { title: "Contact Us", path: "/ContactUs" },
        { title: "Privacy Policy", path: "/Privacy" },
        { title: "Account Setting", path: "/Account" },
        { title: "Terms for use", path: "/Rules" },
        { title: "Email Certification", path: "/JoinCheck" },
        { title: "Credit", path: "/Credit" },
      ]);
    },
  },
});

let language = createSlice({
  name: "language",
  initialState: "",
  reducers: {
    languageChange(state, action) {
      return (state = action.payload);
    },
  },
});

export const { 메뉴변경, 로그인후메뉴, 로그인전메뉴, 게스트메뉴 } =
  메뉴.actions;
export const { 로그인여부변경 } = 로그인여부.actions;
export const { 월드목록변경 } = 월드목록.actions;
export const { HambegerOpen, HambegerClose } = Hamberger.actions;
export const { 알림변경 } = 알림.actions;
export const { 알림내용변경 } = 알림내용.actions;
export const { languageChange } = language.actions;
export const { joinform변경, joinform초기화 } = joinform.actions;

export default configureStore({
  reducer: {
    월드목록: 월드목록.reducer,
    로그인여부: 로그인여부.reducer,
    메뉴: 메뉴.reducer,
    Hamberger: Hamberger.reducer,
    알림: 알림.reducer,
    알림내용: 알림내용.reducer,
    language: language.reducer,
    joinform: joinform.reducer,
  },
});
