import React from "react";
import Styles from "./popupHeader.module.css";

const PopupHeader = (props) => {
  return (
    <>
      <div
        style={{
          height: "35px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.425)",
          width: "100%",
        }}
      >
        <div
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "7px",
            display: "flex",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "WorldManager.ico"}
            alt="Logo"
            height="20px"
            style={{ marginRight: "5px" }}
          />
          <div className={Styles.logotext}>Udon World Manager</div>
        </div>
        <div
          style={{
            float: "right",
            marginRight: "5px",
            marginTop: "6px",
            cursor: "pointer",
          }}
          onClick={props.exitTrigger}
        >
          <img src={process.env.PUBLIC_URL + "Close.svg"} alt="" />
        </div>
      </div>
    </>
  );
};

export default PopupHeader;
