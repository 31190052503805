import Styles from "./Contents.module.css";
import React from "react";
function Contents(props) {
  return (
    <>
      <div
        className={[Styles.contents, props.styleclass].join(" ")}
        style={props.style}
      >
        {props.component}
      </div>
    </>
  );
}

export default Contents;
