import { useTranslation } from "react-i18next";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader";
import Styles from "./CustomTableP.module.css";
import React from "react";

function CustomTableP(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className={Styles.customTable}>
        <ComponentHeader title={props.title} />
        <table style={{ textAlign: "center" }}>
          <thead>
            <tr>
              {props.columnData.map((column, index) => (
                <th key={index}>{t(column)}</th>
              ))}
              <th>{t("Del")}</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index2) => {
              return (
                <tr key={index2}>
                  {props.columnData.map((column, index) =>
                    column === "idx" ? (
                      <td key={index}>{index2 + 1}</td>
                    ) : (
                      <td key={index}>{item[column]}</td>
                    )
                  )}
                  <td
                    onClick={() =>
                      props.popupEvent(
                        item[props.targetname],
                        item[props.targetIndex]
                      )
                    }
                  >
                    🗑
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {props.Components}
        {props.pageable !== undefined && (
          <div className={Styles.pageroot}>
            {props.pageable.pageable.pageNumber !== 0 ? (
              <div
                className={Styles.active}
                onClick={() =>
                  props.pageChange(props.pageable.pageable.pageNumber - 1)
                }
              >
                {`<`}
              </div>
            ) : (
              <div className={Styles.disabled}>{`<`}</div>
            )}

            {Array.from(
              { length: Math.min(props.pageable.totalPages, 5) },
              (_, index) => {
                const pageNumber =
                  props.pageable.pageable.pageNumber - 2 + index;
                if (pageNumber >= 0 && pageNumber < props.pageable.totalPages) {
                  return (
                    <div
                      key={index}
                      onClick={() => props.pageChange(pageNumber)}
                      style={{
                        fontWeight:
                          props.pageable.pageable.pageNumber === pageNumber
                            ? "bold"
                            : "normal",
                        cursor: "pointer",
                      }}
                    >
                      {pageNumber + 1}
                    </div>
                  );
                }
                return null;
              }
            )}

            {props.pageable.pageable.pageNumber !==
            props.pageable.totalPages - 1 ? (
              <div
                className={Styles.active}
                onClick={() =>
                  props.pageChange(props.pageable.pageable.pageNumber + 1)
                }
              >
                {`>`}
              </div>
            ) : (
              <div className={Styles.disabled}>{`>`}</div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default CustomTableP;
