import Styles from "./Rightalignform.module.css";
import React from "react";
function Rightalignform(props) {
  return (
    <>
      <div className={Styles.formbody}>
        <div className={Styles.rightalignform}>{props.component}</div>
      </div>
    </>
  );
}

export default Rightalignform;
