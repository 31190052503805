import { useDispatch, useSelector } from "react-redux";
import Styles from "./Header.module.css";
import React from "react";
import { HambegerOpen } from "../../redux/store/store";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../Contents/LanguageSelector/LanguageSelector";
function Header(props) {
  let dispatch = useDispatch();
  const menuOpen = () => {
    dispatch(HambegerOpen(true));
  };
  const { t } = useTranslation();
  let 로그인여부 = useSelector((state) => {
    return state.로그인여부;
  });
  return (
    <>
      <div className={Styles.header}>
        <div className={Styles.imageContainer}>
          <img src={process.env.PUBLIC_URL + "PA_logo2.svg"} alt={""}></img>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <LanguageSelector />
          <div className={Styles.profile}>
            <img src={process.env.PUBLIC_URL + "Profile.png"} alt={""}></img>
            {!로그인여부 ? (
              <Link to="/Login">{t("Login")}</Link>
            ) : (
              <Link to="/Account">{localStorage.getItem("nickname")}</Link>
            )}
          </div>
          <div
            className={Styles.Hamburger}
            onClick={() => {
              dispatch(menuOpen);
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "hamberger.svg"}
              width="40px"
              alt={""}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
