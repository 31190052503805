import React from "react";
import Chart from "react-apexcharts";

function SimpleLineChart(props) {
  const options = {
    responsive: [
      {
        breakpoint: 750,
        options: {
          chart: {
            height: 200,
          },
          yaxis: {
            tickAmount: 4,
            labels: {},
          },
          xaxis: {
            categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            labels: {
              style: {
                fontSize: "3vw",
                fontWeight: 100,
                cssClass: "mainfont300",
              },
            },
          },
        },
      },
    ],
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: -30,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    theme: {
      mode: "dark",
    },
    chart: {
      type: "line",
      dropShadow: {
        enabled: true,
        top: 10,
        left: 5,
        blur: 5,
        opacity: 0.8,
      },
      background: "transparent",
      toolbar: {
        show: false,
      },
      offsetY: 0,
      offsetX: 0,
      zoom: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#fff"],
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        show: true,
        Text: {
          style: {},
        },
        style: {
          colors: [],
          fontSize: "0.6vw",
          fontWeight: 100,
          cssClass: "mainfont300",
        },
        offsetX: -5,
        minWidth: 1,
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: 4,
      labels: {
        style: {
          colors: [],
          fontSize: "0.6vw",
          fontWeight: 100,
        },
        offsetY: -5,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  let series = [
    {
      name: "Visitor",
      data: props.data,
    },
  ];

  return (
    <div>
      {series === undefined ? (
        <img
          style={{ width: "50%", height: "50%" }}
          src={process.env.PUBLIC_URL + "Nodata.svg"}
          alt={""}
        />
      ) : (
        <Chart options={options} series={series} type="line" />
      )}
    </div>
  );
}

export default SimpleLineChart;
