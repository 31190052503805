import Styles from "./Hamberger.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  HambegerClose,
  로그인여부변경,
  로그인전메뉴,
} from "../../redux/store/store.js";
import { logoutAxios } from "./HambergerAxios.js";
function Hamberger(props) {
  let dispatch = useDispatch();

  let 로그인여부 = useSelector((state) => {
    return state.로그인여부;
  });

  const { t } = useTranslation();
  const closeMenu = () => {
    dispatch(HambegerClose(false));
  };
  const logout = async () => {
    await logoutAxios();
    dispatch(HambegerClose(false));
    dispatch(로그인여부변경(false));
    dispatch(로그인전메뉴());
  };
  return (
    <>
      <div className={Styles.MenuPanel} onClick={closeMenu}>
        <ul
          className={Styles.MenuRoot}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={Styles.profile}>
            <img
              src={process.env.PUBLIC_URL + "Profile.png"}
              alt={""}
              style={{ width: "24px", height: "24px" }}
            ></img>
            {!로그인여부 ? (
              <Link to="/Login" onClick={closeMenu}>
                {t("Login")}
              </Link>
            ) : (
              <Link to="/Account" onClick={closeMenu}>
                {localStorage.getItem("nickname")}
              </Link>
            )}
          </div>

          {props.data.map((item, index) => {
            return (
              <li key={index} className={Styles.MenuElement}>
                <Link to={item.path} onClick={closeMenu}>
                  {t(item.title)}
                </Link>
              </li>
            );
          })}
          {로그인여부 && (
            <>
              <div
                className={Styles.profile}
                style={{ padding: "5px 4px 5px 12px" }}
                onClick={logout}
              >
                <img
                  src={process.env.PUBLIC_URL + "logout.svg"}
                  alt={""}
                  style={{ width: "24px", height: "24px" }}
                />
                <p style={{ margin: "16px 0px" }}>{t("Logout")}</p>
              </div>
            </>
          )}
        </ul>
      </div>
    </>
  );
}

export default Hamberger;
