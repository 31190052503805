import { useTranslation } from "react-i18next";
import Line from "../../../Common/line/Line";
import Styles from "./CompareTable.module.css";
import React, { useEffect, useState } from "react";
function TableHeader(props) {
  const { t } = useTranslation();
  let [날짜조건, set날짜조건] = useState(0);
  let [날짜, set날짜] = useState(0);
  useEffect(() => {
    날짜조정();
  }, [props.date]);
  function 날짜조정() {
    let date = props.date;

    if (date === 0) date = 720;
    if (date === 1) date = 1;
    else if (date === 2) date = 7;
    else if (date === 3) date = 30;
    else if (date === 4) date = 365;
    set날짜조건(date);

    if (date === 1) set날짜(t("day"));
    else if (date === 7) set날짜(t("week"));
    else if (date === 30) set날짜(t("month"));
    else if (date === 365) set날짜(t("Year"));
    else if (date === 720) set날짜(t("None"));
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
      className={Styles.header}
    >
      <div className={Styles.title}>
        <br></br>
        <div className={Styles.OptDate}>{날짜}</div>
        <div style={{ fontSize: "24px" }}>
          {new Date(
            new Date().getTime() - 날짜조건 * 24 * 60 * 60 * 1000
          ).getFullYear()}
          -
          {new Date(
            new Date().getTime() - 날짜조건 * 24 * 60 * 60 * 1000
          ).getMonth() + 1}
          -
          {new Date(
            new Date().getTime() - 날짜조건 * 24 * 60 * 60 * 1000
          ).getDate()}{" "}
          ~ {new Date().getFullYear()}-{new Date().getMonth() + 1}-
          {new Date().getDate()}
        </div>
      </div>
      <br></br>
      <Line
        style={{
          borderBottom: "0.1px solid rgba(255, 255, 255, 0.4)",
        }}
      />
      {props.components}
    </div>
  );
}

export default TableHeader;
