import React, { useState, useEffect } from "react";
import Contents from "../../Contents/Layout/Contents.jsx";
import ContentsHeader from "../../Contents/Layout/ContentsHeader.jsx";
import Contentstitle from "../../Contents/Layout/Contentstitle.jsx";
import Subcontents from "../../Contents/Layout/Subcontents.jsx";
import LineInput from "../../Common/Input/LineInput.tsx";
import TextButton from "../../Common/button/TextButton.jsx";
import Neonbutton from "../../Common/button/Neonbutton.tsx";
import Submidlecontents from "../../Contents/Layout/Submidlecontents.jsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import join from "./JoinAxios.ts";
import {
  알림내용변경,
  알림변경,
  게스트메뉴,
  로그인여부변경,
  joinform변경,
  joinform초기화,
} from "../../redux/store/store.js";
import { useDispatch, useSelector } from "react-redux";
import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader.jsx";
import Styles from "./Join.module.css";

const Join = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const joinform = useSelector((state) => state.joinform);

  const [errors, setErrors] = useState({
    nickName: "",
    email: "",
    password: "",
    passwordCheck: "",
  });
  const goToCheck = () => {
    dispatch(joinform초기화());

    navigate("/JoinCheck");
  };
  const goToLogin = () => {
    dispatch(joinform초기화());
    navigate("/Login");
  };
  const handleShowToast = (code, text) => {
    dispatch(
      알림내용변경({
        code: code,
        text: text,
      })
    );
    dispatch(알림변경(true));
  };

  const validateNickname = (nickname) => {
    const nicknameReg = /^[a-zA-Z0-9]{4,10}$/;
    if (!nicknameReg.test(nickname)) {
      return "Nickname must be 2~10 characters without spaces or special characters";
    }
    return "";
  };

  const validateEmail = (email) => {
    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailReg.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  const validatePassword = (password) => {
    const passwordReg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{10,32}$/;
    if (!passwordReg.test(password)) {
      return "Password must be at least 10 ~ 32 characters long and must contain a special character";
    }
    return "";
  };

  const validatePasswordCheck = (password, passwordCheck) => {
    if (password !== passwordCheck) {
      return "Password does not match";
    }
    return "";
  };

  const handleJoinForm = (e, name) => {
    const value =
      name === "privarcy" || name === "rulse"
        ? e.target.checked
        : e.target.value;
    dispatch(joinform변경({ ...joinform, [name]: value }));

    let error = "";
    switch (name) {
      case "nickName":
        error = validateNickname(value);
        break;
      case "email":
        error = validateEmail(value);
        break;
      case "password":
        error = validatePassword(value);
        break;
      case "passwordCheck":
        error = validatePasswordCheck(joinform.password, value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: error });
  };

  const CheckPassword = () => {
    if (
      joinform.nickName === "" ||
      joinform.email === "" ||
      joinform.password === "" ||
      joinform.passwordCheck === ""
    ) {
      handleShowToast("EMPTY_INPUT", "Please fill in all the information");
      return;
    }

    if (
      errors.nickName ||
      errors.email ||
      errors.password ||
      errors.passwordCheck
    ) {
      handleShowToast("FORM_ERROR", "Please correct the errors in the form");
      return;
    }

    if (!joinform.privarcy) {
      handleShowToast("PRIVACY_RULE", "Please agree to the Privacy Policy");
      return;
    }

    if (!joinform.rulse) {
      handleShowToast("RULES_RULE", "Please agree to the Terms for use");
      return;
    }

    join(joinform).then((response) => {
      if (response.header.code === "USER_CREATE_SUCCESS") {
        const { accessToken } = response.data.token;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("nickname", response.data.nickName);
        localStorage.setItem("user", response.data.idx);
        localStorage.setItem("authority", response.data.authority.name);
        dispatch(게스트메뉴());
        dispatch(로그인여부변경(true));
        dispatch(joinform초기화());
        goToCheck();
      } else {
        handleShowToast(response.header.status, response.header.code);
      }
    });
  };

  return (
    <>
      <Contents
        component={
          <>
            <ComponentHeader title={t("Join in")} />
            <ContentsHeader
              component={<Contentstitle title={t("Join in")} />}
            />
            <Subcontents
              component={
                <Submidlecontents
                  component={
                    <>
                      <LineInput
                        label={t("Username")}
                        placeholder={t("Enter Your Nickname")}
                        type={"text"}
                        value={joinform.nickName}
                        onChange={(e) => handleJoinForm(e, "nickName")}
                      />
                      {errors.nickName && (
                        <p className={Styles.errtext}>{t(errors.nickName)}</p>
                      )}
                      <LineInput
                        label={t("Email")}
                        placeholder={"Alice@mystialolelei.site"}
                        type={"email"}
                        value={joinform.email}
                        onChange={(e) => handleJoinForm(e, "email")}
                      />
                      {errors.email && (
                        <p className={Styles.errtext}>{t(errors.email)}</p>
                      )}
                      <LineInput
                        label={t("password")}
                        placeholder={t("Enter Your PassWord")}
                        type={"password"}
                        value={joinform.password}
                        onChange={(e) => handleJoinForm(e, "password")}
                      />
                      {errors.password && (
                        <p className={Styles.errtext}>{t(errors.password)}</p>
                      )}
                      <LineInput
                        label={t("password Check")}
                        placeholder={t("Enter Your PassWord")}
                        type={"password"}
                        value={joinform.passwordCheck}
                        onChange={(e) => handleJoinForm(e, "passwordCheck")}
                      />
                      {errors.passwordCheck && (
                        <p className={Styles.errtext}>
                          {t(errors.passwordCheck)}
                        </p>
                      )}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="checkbox"
                          id="privarcy"
                          name="privarcy"
                          checked={joinform.privarcy}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          onChange={(e) => handleJoinForm(e, "privarcy")}
                        />
                        <label
                          htmlFor="privarcy"
                          style={{ marginRight: "10px" }}
                        >
                          {t("I agree to the Privacy Policy")}
                        </label>
                        <p
                          style={{
                            margin: "0",
                            textShadow: "0 0 10px #03f0fa",
                            color: "#03f0fa",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/Privacy");
                          }}
                        >
                          (Link)
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="rulse"
                          name="rulse"
                          checked={joinform.rulse}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          onChange={(e) => handleJoinForm(e, "rulse")}
                        />
                        <label htmlFor="rulse" style={{ marginRight: "10px" }}>
                          {t("I agree to the Terms for use")}
                        </label>
                        <p
                          style={{
                            margin: "0",
                            textShadow: "0 0 10px #03f0fa",
                            color: "#03f0fa",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/Rules");
                          }}
                        >
                          (Link)
                        </p>
                      </div>
                    </>
                  }
                />
              }
            />
            <br />
            <Subcontents
              component={
                <>
                  <Neonbutton text={t("Join")} onClick={CheckPassword} />
                  <br />
                  <TextButton text={t("Login")} onClick={goToLogin} />
                  <br />
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default Join;
