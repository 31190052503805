import ComponentHeader from "../../Layout/ComponentHeader/ComponentHeader";
import Styles from "./CustomTableM.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
function CustomTableM(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className={Styles.customTable}>
        <ComponentHeader title={props.title} />
        <div className={Styles.tableContainer}>
          {props.data.map((item, index2) => (
            <div className={Styles.row} key={index2}>
              {props.columnData.map((column, index) => (
                <div key={index}>
                  <span>
                    <strong>{t(column)}</strong>
                  </span>
                  <span>{column === "idx" ? index2 + 1 : item[column]}</span>
                </div>
              ))}
              <div
                style={{ cursor: "pointer", justifyContent: "center" }}
                className={Styles.deleteButton}
                onClick={() =>
                  props.popupEvent(
                    item[props.targetname],
                    item[props.targetIndex]
                  )
                }
              >
                🗑 {t("Delete")}
              </div>
            </div>
          ))}
        </div>
        {props.Components}
        {props.pageable !== undefined && (
          <div className={Styles.pageroot}>
            {props.pageable.pageable.pageNumber !== 0 ? (
              <div
                className={Styles.active}
                onClick={() =>
                  props.pageChange(props.pageable.pageable.pageNumber - 1)
                }
              >
                {`<`}
              </div>
            ) : (
              <div className={Styles.disabled}>{`<`}</div>
            )}

            {Array.from(
              { length: Math.min(props.pageable.totalPages, 5) },
              (_, index) => {
                const pageNumber =
                  props.pageable.pageable.pageNumber - 2 + index;
                if (pageNumber >= 0 && pageNumber < props.pageable.totalPages) {
                  return (
                    <div
                      key={index}
                      onClick={() => props.pageChange(pageNumber)}
                      style={{
                        fontWeight:
                          props.pageable.pageable.pageNumber === pageNumber
                            ? "bold"
                            : "normal",
                        cursor: "pointer",
                      }}
                    >
                      {pageNumber + 1}
                    </div>
                  );
                }
                return null;
              }
            )}

            {props.pageable.pageable.pageNumber !==
            props.pageable.totalPages - 1 ? (
              <div
                className={Styles.active}
                onClick={() =>
                  props.pageChange(props.pageable.pageable.pageNumber + 1)
                }
              >
                {`>`}
              </div>
            ) : (
              <div className={Styles.disabled}>{`>`}</div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default CustomTableM;
